.yellowLine2 {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    background: var(--my-yellow);
    height: 3%;
    z-index: 52;
}

.section3-container {
    grid-row: 3 / 4;
    grid-column: full-start / full-end;
    background-color: var(--my-background);

    display: grid;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
    grid-template-rows: repeat(28, 19.3rem);
    z-index: 53;


    .white-bgBar1 {
        grid-column: 1 / 7;
        grid-row: 1 / 2;
        height: 5%;
        width: 100%;
        background: linear-gradient(to left, rgba(194, 190, 190, 0.2), #fff 70%);
        align-self: center;
        z-index: 52;
    }

    // WHITE BACKGROUND
    .white-background {
        grid-row: 2 / 29;
        grid-column: full-start / full-end;
        background-color: #fff;
        width: 85%;
        height: 96%;
        align-self: end;
        justify-self: center;

        display: grid;
        grid-template-rows: repeat(40, 1fr);
        grid-template-columns: repeat(5, 1fr);
        &__border-line {
            grid-row: 1 / 41;
            width: .2rem;
            height: 100%;
            box-shadow: .1rem .1rem .1rem #ff5924;
            margin-left: 4rem;
        }
        &__paper-line1 {
            grid-row: 1 / 2;
            // grid-column: 1 / 2;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line2 {
            grid-row: 2 / 3;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line3 {
            grid-row: 3 / 4;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line4 {
            grid-row: 4 / 5;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line5 {
            grid-row: 5 / 6;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line6 {
            grid-row: 6 / 7;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
    }

    // BLACK BACKGROUND
    .black-background {
        grid-row: 2 / 29;
        grid-column: 1 / 7;
        width: 75%;
        height: 96%;
        justify-self: center;
        align-self: start;
        background-color: #000;
    }
    .projects-header {
        text-transform: uppercase;
        font-size: 7rem;
        font-weight: 400;
        grid-row: 2 / 3;
        grid-column: 2 / 4;
        margin-left: 6rem;
        margin-top: 3rem;
        align-self: center;
        z-index: 53;
        opacity: 0;
        transition: opacity 1200ms ease-in;
    }


     .project-container1__project-text1__overkill {
        color: var(--my-red);
    }

    // PROJECT 1
    .project-container1 {
        grid-row: 3 / 7;
        grid-column: 2 / 6;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(7, 1fr);
        z-index: 52;
        // &__dev-pro {
        //     grid-row: 1 / 2;
        //     grid-column: 4 / 8;
        //     text-transform: uppercase;
        //     font-size: 2.7rem;
        //     font-weight: 400;
        //     align-self: center;
        //     justify-self: center;
        // }
        &__box1 {
            grid-row: 1 / 4;
            grid-column: 3 / 9;
            width: 100%;
            justify-self: start;
            align-self: center;
            margin-left: -2rem;
            cursor: pointer;

            &__devPortfolio {
                width: 90%;
            }
        }
        
        &__project-title1 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: end;
            align-self: center;
        }
        &__project-title2 {
            grid-column: 6 / 9;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: start;
            align-self: center;
            color: var(--my-code-blue);
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .3rem;
            height: 20rem;
            align-self: center;
            justify-self: center;
            background-color: #fff;
        }
        &__fullyResponsive {
            grid-column: 7 / 10;
            grid-row: 3 / 4;
            align-self: end;
            margin-bottom: -2rem;
            text-transform: capitalize;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__project-text1 {
            grid-column: 1 / 5;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: end;
            font-weight: 400;
        }
        &__project-text2 {
            grid-column: 6 / 10;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: start;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__tool-stack {
            grid-row: 1 / 2;
            grid-column: 11 / 13;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 600;
            align-self: start;
            margin-top: 3rem;
            justify-self: center;

            &__my-role {
                grid-row: 1 / 2;
                grid-column: 11 / 13;
                text-transform: capitalize;
                font-size: 1.7rem;
                align-self: end;
                justify-self: center;
                color: var(--my-code-blue);
            }
            &__list {
                grid-row: 2 / 6;
                grid-column: 11 / 13;
                justify-self: center;
                height: 88%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__breakLine1 {
            grid-row: 7 / 8;
            grid-column: 1 / 5;
            background-color: #fff;
            width: 85%;
            height: 5%;
            align-self: end;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            grid-row: 7 / 8;
            font-size: 6rem;
            justify-self: center;
            align-self: end;
            margin-bottom: -3.6rem;

            .br {
                color: var(--my-red);
            }
        }
        &__breakLine2 {
            grid-row: 7 / 8;
            grid-column: 6 / 13;
            background-color: #fff;
            width: 90%;
            height: 5%;
            align-self: end;
            justify-self: end;
        }
    }


    // PROJECT 2
    .project-container2 {
        grid-row: 8 / 12;
        grid-column: 2 / 6;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(7, 1fr);
        z-index: 52;
        &__before {
            grid-row: 1 / 2;
            grid-column: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
            justify-self: center;
            margin-left: 1rem;
        }
        &__box1 {
            grid-row: 2 / 4;
            grid-column: 1 / 5;
            width: 90%;
            justify-self: end;
            align-self: center;
            cursor: pointer;

            &__oldBrac {
                width: 100%;
            }
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            align-self: end;
            margin-bottom: 1rem;
            margin-left: -.5rem;

        }
        &__equalLine2 {
            grid-row: 3 / 4;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            margin-top: 1rem;
            margin-left: -.5rem;
        }
        &__after {
            grid-row: 1 / 2;
            grid-column: 6 / 7;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
        }
        &__box2 {
            grid-row: 2 / 4;
            grid-column: 6 / 10;
            width: 90%;
            justify-self: start;
            align-self: center;
            cursor: pointer;

            &__newBrac {
                width: 100%;
            }
        }
        &__project-title1 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: end;
            align-self: center;
        }
        &__project-title2 {
            grid-column: 6 / 9;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: start;
            align-self: center;
            color: var(--my-code-blue);
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .3rem;
            height: 20rem;
            align-self: center;
            justify-self: center;
            background-color: #fff;
        }
        &__fullyResponsive {
            grid-column: 7 / 10;
            grid-row: 3 / 4;
            align-self: end;
            margin-bottom: -2rem;
            text-transform: capitalize;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__project-text1 {
            grid-column: 1 / 5;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: end;
            font-weight: 400;
        }
        &__project-text2 {
            grid-column: 6 / 10;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: start;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__tool-stack {
            grid-row: 1 / 2;
            grid-column: 11 / 13;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 600;
            align-self: start;
            margin-top: 3rem;
            justify-self: center;

            &__my-role {
                grid-row: 1 / 2;
                grid-column: 11 / 13;
                text-transform: capitalize;
                font-size: 1.7rem;
                align-self: end;
                justify-self: center;
                color: var(--my-code-blue);
            }
            &__list {
                grid-row: 2 / 6;
                grid-column: 11 / 13;
                justify-self: center;
                height: 88%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
        &__hand {
            grid-row: 5;
            grid-column: 10 / 13;
            justify-self: center;
            align-self: end;
            margin-left: 2rem;
            margin-bottom: -3.5rem;
            animation: MoveUpDown 1.5s infinite;  
        }
        
        &__brac-study {
            grid-row: 6;
            grid-column: 10 / 13;
            justify-self: center;
            align-self: end;
            margin-left: 2rem;
            text-decoration: none;

            display: grid;
            grid-template-rows: 2 repeat(1fr);

            &__btn {
                grid-row: 1 / 2;
                background-color: transparent;
                cursor: pointer;
                border: none;
                font-size: 1.7rem;
                font-family: Coming Soon;
                color: var(--my-orange);
                animation: btn-pulse 1.5s infinite;

                &__line {
                    grid-row: 2 / 3;
                    background-color: #fff;
                    height: .1rem;
                    justify-self: center;
                    align-self: end;
                    margin-top: 1rem;
                    width: 60%;
                }
            }
        
        }

        &__breakLine1 {
            grid-row: 7 / 8;
            grid-column: 1 / 5;
            background-color: #fff;
            width: 85%;
            height: 5%;
            align-self: end;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            grid-row: 7 / 8;
            font-size: 6rem;
            justify-self: center;
            align-self: end;
            margin-bottom: -3.6rem;

            .br {
                color: var(--my-red);
            }
        }
        &__breakLine2 {
            grid-row: 7 / 8;
            grid-column: 6 / 13;
            background-color: #fff;
            width: 90%;
            height: 5%;
            align-self: end;
            justify-self: end;
        }
    }




    // PROJECT 3
    .project-container3 {
        grid-row: 13 / 17;
        grid-column: 2 / 6;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(7, 1fr);
        z-index: 52;
        &__before {
            grid-row: 1 / 2;
            grid-column: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
            justify-self: center;
            margin-left: 1rem;
        }
        &__box1 {
            grid-row: 2 / 4;
            grid-column: 1 / 5;
            width: 90%;
            justify-self: end;
            align-self: center;
            cursor: pointer;

            &__oldDominicks {
                width: 100%;
            }
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            align-self: end;
            margin-bottom: 1rem;
            margin-left: -.5rem;

        }
        &__equalLine2 {
            grid-row: 3 / 4;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            margin-top: 1rem;
            margin-left: -.5rem;
        }
        &__after {
            grid-row: 1 / 2;
            grid-column: 6 / 7;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
        }
        &__box2 {
            grid-row: 2 / 4;
            grid-column: 6 / 10;
            width: 90%;
            justify-self: start;
            align-self: center;
            cursor: pointer;

            &__newDominicks {
                width: 100%;
            }
        }
        &__project-title1 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: end;
            align-self: center;
        }
        &__project-title2 {
            grid-column: 6 / 9;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: start;
            align-self: center;
            color: var(--my-code-blue);
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .3rem;
            height: 20rem;
            align-self: center;
            justify-self: center;
            background-color: #fff;
        }
        &__fullyResponsive {
            grid-column: 7 / 10;
            grid-row: 3 / 4;
            align-self: end;
            margin-bottom: -2rem;
            text-transform: capitalize;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__project-text1 {
            grid-column: 1 / 5;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: end;
            font-weight: 400;
        }
        &__project-text2 {
            grid-column: 6 / 10;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: start;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__tool-stack {
            grid-row: 1 / 2;
            grid-column: 11 / 13;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 600;
            align-self: start;
            margin-top: 3rem;
            justify-self: center;

            &__my-role {
                grid-row: 1 / 2;
                grid-column: 11 / 13;
                text-transform: capitalize;
                font-size: 1.7rem;
                align-self: end;
                justify-self: center;
                color: var(--my-code-blue);
            }
            &__list {
                grid-row: 2 / 6;
                grid-column: 11 / 13;
                justify-self: center;
                height: 88%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__breakLine1 {
            grid-row: 7 / 8;
            grid-column: 1 / 5;
            background-color: #fff;
            width: 85%;
            height: 5%;
            align-self: end;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            grid-row: 7 / 8;
            font-size: 6rem;
            justify-self: center;
            align-self: end;
            margin-bottom: -3.6rem;

            .br {
                color: var(--my-red);
            }
        }
        &__breakLine2 {
            grid-row: 7 / 8;
            grid-column: 6 / 13;
            background-color: #fff;
            width: 90%;
            height: 5%;
            align-self: end;
            justify-self: end;
        }
    }




    // PROJECT 4
    .project-container4 {
        grid-row: 18 / 22;
        grid-column: 2 / 6;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(7, 1fr);
        z-index: 52;
        &__before {
            grid-row: 1 / 2;
            grid-column: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
            justify-self: center;
            margin-left: 1rem;
        }
        &__box1 {
            grid-row: 2 / 4;
            grid-column: 1 / 5;
            width: 90%;
            justify-self: end;
            align-self: center;
            cursor: pointer;

            &__oldBCM {
                width: 100%;
            }
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            align-self: end;
            margin-bottom: 1rem;
            margin-left: -.5rem;

        }
        &__equalLine2 {
            grid-row: 3 / 4;
            grid-column: 5 / 6;
            background-color: var(--my-red);
            width: 45%;
            height: 7%;
            justify-self: center;
            margin-top: 1rem;
            margin-left: -.5rem;
        }
        &__after {
            grid-row: 1 / 2;
            grid-column: 6 / 7;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            align-self: center;
        }
        &__box2 {
            grid-row: 2 / 4;
            grid-column: 6 / 10;
            width: 90%;
            justify-self: start;
            align-self: center;
            cursor: pointer;

            &__newBCM {
                width: 100%;
            }
        }
        &__project-title1 {
            grid-column: 3 / 5;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: end;
            align-self: center;
        }
        &__project-title2 {
            grid-column: 6 / 9;
            grid-row: 4 / 5;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: start;
            align-self: center;
            color: var(--my-code-blue);
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .3rem;
            height: 20rem;
            align-self: center;
            justify-self: center;
            background-color: #fff;
        }
        &__fullyResponsive {
            grid-column: 7 / 10;
            grid-row: 3 / 4;
            align-self: end;
            margin-bottom: -2rem;
            text-transform: capitalize;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__project-text1 {
            grid-column: 1 / 5;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: end;
            font-weight: 400;
        }
        &__project-text2 {
            grid-column: 6 / 10;
            grid-row: 5 / span 2;
            align-self: start;
            text-align: start;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__tool-stack {
            grid-row: 1 / 2;
            grid-column: 11 / 13;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 600;
            align-self: start;
            margin-top: 3rem;
            justify-self: center;

            &__my-role {
                grid-row: 1 / 2;
                grid-column: 11 / 13;
                text-transform: capitalize;
                font-size: 1.7rem;
                align-self: end;
                justify-self: center;
                color: var(--my-code-blue);
            }
            &__list {
                grid-row: 2 / 6;
                grid-column: 11 / 13;
                justify-self: center;
                height: 55%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }

        &__breakLine1 {
            grid-row: 7 / 8;
            grid-column: 1 / 5;
            background-color: #fff;
            width: 85%;
            height: 5%;
            align-self: end;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            grid-row: 7 / 8;
            font-size: 6rem;
            justify-self: center;
            align-self: end;
            margin-bottom: -3.6rem;

            .br {
                color: var(--my-red);
            }
        }
        &__breakLine2 {
            grid-row: 7 / 8;
            grid-column: 6 / 13;
            background-color: #fff;
            width: 90%;
            height: 5%;
            align-self: end;
            justify-self: end;
        }
    }

    // PROJECT 5
    .project-container5 {
        grid-row: 23 / 27;
        grid-column: 2 / 6;

        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(9, 1fr);
        z-index: 52;
    
        &__box1 {
            grid-row: 1 / 4;
            grid-column: 2 / 4;
            width: 100%;
            justify-self: start;
            align-self: end;
            cursor: pointer;

            &__HighFidel1 {
                width: 100%;
            }
        }
        &__box2 {
            grid-row: 1 / 4;
            grid-column: 5 / 7;
            width: 100%;
            justify-self: start;
            align-self: end;
            cursor: pointer;

            &__HighFidel2 {
                width: 100%;
            }
        }
        &__box3 {
            grid-row: 1 / 4;
            grid-column: 8 / 10;
            width: 100%;
            justify-self: start;
            align-self: end;
            cursor: pointer;

            &__BCMicon {
                width: 100%;
            }
        }
        &__box4 {
            grid-row: 4 / 7;
            grid-column: 3 / 9;
            width: 79%;
            justify-self: center;
            align-self: center;
            cursor: pointer;

            &__BracDesign {
                width: 100%;
            }
        }
        &__project-title1 {
            grid-column: 5 / 7;
            grid-row: 7 / 8;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 400;
            justify-self: center;
            align-self: center;
            margin-top: -2rem;
            color: var(--my-code-blue);
        }
        &__high-fidelity {
            grid-column: 8 / 10;
            grid-row: 7 / 8;
            align-self: start;
            text-transform: capitalize;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__project-text1 {
            grid-column: 3 / 9;
            grid-row: 8 / span 2;
            align-self: start;
            text-align: center;
            font-weight: 400;
            color: var(--my-code-blue);
        }
        &__tool-stack5 {
            grid-row: 2 / 3;
            grid-column: 10 / 13;
            text-transform: uppercase;
            font-size: 2.7rem;
            font-weight: 600;
            align-self: start;
            justify-self: center;


            &__my-role5 {
                grid-row: 2 / 3;
                grid-column: 10 / 13;
                text-transform: capitalize;
                font-size: 1.7rem;
                align-self: end;
                justify-self: center;
                padding-bottom: 2rem;
                color: var(--my-code-blue);
            }
            &__list {
                grid-row: 3 / 4;
                grid-column: 10 / 13;
                height: 95%;
                justify-self: center;
                align-self: center;

                display: flex;
                flex-direction: column;
                & li {
                    padding-top: 2rem;
                }
            }
        }

        // &__breakLine1 {
        //     grid-row: 6 / 7;
        //     grid-column: 1 / 5;
        //     background-color: #fff;
        //     width: 100%;
        //     height: 5%;
        //     align-self: end;
        // }
        // &__lineBreak {
        //     grid-column: 5 / 7;
        //     grid-row: 6 / 7;
        //     font-size: 6rem;
        //     justify-self: center;
        //     align-self: end;
        //     margin-bottom: -3.6rem;

        //     .br {
        //         color: var(--my-red);
        //     }
        // }
        // &__breakLine2 {
        //     grid-row: 6 / 7;
        //     grid-column: 7 / 13;
        //     background-color: #fff;
        //     width: 100%;
        //     height: 5%;
        //     align-self: end;
        // }
    }
}

.blue-color {
    color: var(--my-code-blue);
}
.red-color {
    color: var(--my-red);
    font-weight: 600;
    font-size: 1.3rem;
}


/////////////////////////////////
////////////////////////////////


@keyframes btn-pulse {
    0%, 100% {
        opacity: .4;
        color: var(--my-orange);
    }
    60% {
        opacity: 1;
        color: var(--my-green);
    }
}

@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
        opacity: .4;
    }
    60% {
        transform: translateY(3rem);
        opacity: 1;
    }
}




///// UP STAGS /////
.projects-stag-up {
    opacity: 0;
    transition: opacity 1200ms ease-in;
}
.projects-stag-up.projects-animate-up {
    animation: animationUp 1000ms;
    opacity: 1;
}

///// LEFT STAG /////
.projects-header.projects-animate-left {
    animation: animationLeft 1300ms;
    opacity: 1;
}






///// MEDIA QUERIES /////

// 1400px
@media screen and (max-width: 1400px) {
    // .section3-container .project-container2__tool-stack__my-role {
    //     margin-right: 7rem;
    // }
    .section3-container .project-container5__tool-stack__my-role5 {
        margin-left: 0rem;
    }


    .section3-container {


        // PROJECT 1
        .project-container1 {
            grid-row: 3 / 7;
            grid-column: 2 / 6;
    
            &__dev-pro {
                grid-row: 1 / 2;
                grid-column: 4 / 8;
            }
            &__box1 {
                grid-row: 2 / 4;
    
                &__devPortfolio {
                    width: 95%;
                }
            }
    
            &__fullyResponsive {
                grid-column: 7 / 11;
                grid-row: 1 / 2;
                align-self: end;
                margin-bottom: 1rem;
            }
            
            &__tool-stack {
                grid-row: 1 / 2;
                grid-column: 10 / 13;

                &__my-role {
                    grid-row: 1 / 2;
                    grid-column: 10 / 13;
                }
                &__list {
                    grid-row: 2 / 6;
                    grid-column: 10 / 13;
                    height: 88%;
                }
            }
        }



            // PROJECT 2
        .project-container2 {
            grid-row: 8 / 12;
            grid-column: 2 / 6;

            
            &__fullyResponsive {
                grid-column: 7 / 11;
                grid-row: 1 / 2;
                align-self: end;
                margin-bottom: 1rem;
            }
            
            &__tool-stack {
                grid-row: 1 / 2;
                grid-column: 10 / 13;

                &__my-role {
                    grid-row: 1 / 2;
                    grid-column: 10 / 13;
                }
                &__list {
                    grid-row: 2 / 6;
                    grid-column: 10 / 13;
                    height: 88%;
                }
            }

        }


            // PROJECT3
        .project-container3 {
            grid-row: 13 / 17;
            grid-column: 2 / 6;

          
            &__fullyResponsive {
                grid-column: 7 / 11;
                grid-row: 1 / 2;
                align-self: end;
                margin-bottom: 1rem;
            }
            
            &__tool-stack {
                grid-row: 1 / 2;
                grid-column: 10 / 13;

                &__my-role {
                    grid-row: 1 / 2;
                    grid-column: 10 / 13;
                }
                &__list {
                    grid-row: 2 / 6;
                    grid-column: 10 / 13;
                    height: 88%;
                }
            }
        }


        // PROJECT4
        .project-container4 {
            grid-column: 2 / 6;

            &__fullyResponsive {
                grid-column: 7 / 10;
                grid-row: 3 / 4;
            }
            
            &__tool-stack {
                grid-row: 1 / 2;
                grid-column: 10 / 13;

                &__my-role {
                    grid-row: 1 / 2;
                    grid-column: 10 / 13;
                }
                &__list {
                    grid-row: 2 / 6;
                    grid-column: 10 / 13;
                    height: 55%;
                }
            }
        }

        
        // PROJECT 5
        .project-container5 {
            grid-row: 23 / 27;
            grid-column: 2 / 6;

        
            &__box1 {
                grid-row: 1 / 4;
                grid-column: 1 / 3;

                &__HighFidel1 {
                    width: 15rem;
                }
            }
            &__box2 {
                grid-row: 1 / 4;
                grid-column: 4 / 6;

                &__HighFidel2 {
                    width: 15rem;
                }
            }
            &__box3 {
                grid-row: 1 / 4;
                grid-column: 7 / 9;

                &__BCMicon {
                    width: 15rem;
                }
            }
            &__box4 {
                grid-row: 4 / 7;
                grid-column: 2 / 8;
                margin-left: 5rem;

                &__BracDesign {
                    width: 35rem;
                }
            }
            &__project-title5 {
                grid-column: 3 / 7;
                grid-row: 7 / 8;
                align-self: start;
            }
            &__high-fidelity {
                grid-column: 7 / 10;
                grid-row: 6 / 7;
                align-self: end;
                text-transform: capitalize;
                font-weight: 400;
                color: var(--my-code-blue);
            }
            &__project-text5 {
                grid-column: 1 / 9;
                grid-row: 8 / 9;
                text-align: center;
                align-self: start;
                font-weight: 400;
            }
            &__tool-stack5 {
                grid-row: 2 / 3;
                grid-column: 10 / 13;
                align-self: center;
                justify-self: center;

                &__my-role5 {
                    grid-row: 2 / 3;
                    grid-column: 10 / 13;
                    text-transform: capitalize;
                    font-size: 1.7rem;
                    align-self: end;
                    justify-self: center;
                    padding-bottom: 0rem;
                    color: var(--my-code-blue);
                }
                &__list {
                    grid-row: 3 / 5;
                    grid-column: 10 / 13;
                    height: 95%;
                    justify-self: center;
                    align-self: center;

                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    
}

// 1280px
@media screen and (max-width: 1280px) {
    .section3-container {

        // PROJECT 1
        .project-container1 {
            grid-row: 3 / 7;
            grid-column: 2 / 6;
    
            &__fullyResponsive {
                margin-top: 0rem;
            }
            &__project-title1 {
                font-size: 2.2rem;
            }
            &__project-title2 {
                font-size: 2.2rem;
            }
            &__project-text1 {
                font-size: 1.5rem;
            }
            &__project-text2 {
                font-size: 1.5rem;
            }
            &__project-text-line {
                grid-column: 5 / 6;
                grid-row: 4 / 7;
                width: .2rem;
                height: 15rem;
            }

            &__breakLine1 {
                grid-row: 7 / 8;
                grid-column: 1 / 4;
                background-color: #fff;
                width: 100%;
                height: 5%;
                align-self: end;
            }
            &__breakLine2 {
                grid-row: 7 / 8;
                grid-column: 7 / 13;
                background-color: #fff;
                width: 100%;
                height: 5%;
                align-self: end;
                justify-self: end;
            }
        }

        // PROJECT 2
        .project-container2 {
    
    
            &__fullyResponsive {
                margin-top: 0rem;
            }
            &__project-title1 {
                font-size: 2.2rem;
            }
            &__project-title2 {
                font-size: 2.2rem;
            }
            &__project-text1 {
                font-size: 1.5rem;
            }
            &__project-text2 {
                font-size: 1.5rem;
            }
            &__project-text-line {
                grid-column: 5 / 6;
                grid-row: 4 / 7;
                width: .2rem;
                height: 15rem;
            }

            &__hand {
                width: 3.2rem;
            }
            &__brac-study__btn {
                font-size: 1.5rem;
            }
            &__brac-study__btn__line {
                width: 49%;
            }

            &__breakLine1 {
                grid-row: 7 / 8;
                grid-column: 1 / 4;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
            }
            &__breakLine2 {
                grid-row: 7 / 8;
                grid-column: 7 / 13;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
                justify-self: end;
            }
        }

        // PROJECT 3
        .project-container3 {
    
    
            &__fullyResponsive {
                margin-top: 0rem;
            }
            &__project-title1 {
                font-size: 2.2rem;
            }
            &__project-title2 {
                font-size: 2.2rem;
            }
            &__project-text1 {
                font-size: 1.5rem;
            }
            &__project-text2 {
                font-size: 1.5rem;
            }
            &__project-text-line {
                grid-column: 5 / 6;
                grid-row: 4 / 7;
                width: .2rem;
                height: 15rem;
            }

            &__breakLine1 {
                grid-row: 7 / 8;
                grid-column: 1 / 4;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
            }
            &__breakLine2 {
                grid-row: 7 / 8;
                grid-column: 7 / 13;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
                justify-self: end;
            }
        }

        // PROJECT 4
        .project-container4 {
    
    
            &__fullyResponsive {
                margin-top: 0rem;
            }
            &__project-title1 {
                font-size: 2.2rem;
            }
            &__project-title2 {
                font-size: 2.2rem;
            }
            &__project-text1 {
                font-size: 1.5rem;
            }
            &__project-text2 {
                font-size: 1.5rem;
            }
            &__project-text-line {
                grid-column: 5 / 6;
                grid-row: 4 / 7;
                width: .2rem;
                height: 15rem;
            }

            &__breakLine1 {
                grid-row: 7 / 8;
                grid-column: 1 / 4;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
            }
            &__breakLine2 {
                grid-row: 7 / 8;
                grid-column: 7 / 13;
                background-color: #fff;
                width: 105%;
                height: 5%;
                align-self: end;
                justify-self: end;
            }
        }
        // PROJECT 5
        .project-container5 {


            &__project-text1 {
                font-size: 1.5rem;
            }
        }
    }
}

// 1250px
@media screen and (max-width: 1250px) {
    .section3-container {

        // PROJECT 1
        .project-container1 {
            grid-row: 3 / 7;
            grid-column: 2 / 6;
    
            &__fullyResponsive {
                grid-column: 6 / 10;
                grid-row: 1 / 2;
                align-self: end;
                margin-top: 0rem;
                margin-bottom: 0rem;
            }
        }

        // PROJECT 4
        .project-container4 {

            &__fullyResponsive {
                grid-column: 6 / 10;
                grid-row: 3 / 4;
                justify-self: center;
            }
        }
    }
}

// 1160px
@media screen and (max-width: 1160px) {

    .section3-container {
        // PROJECT 2
        .project-container2 {
            &__hand {
                width: 3rem;
                grid-column: 10 / 13;
                // margin-bottom: 0rem;
            }
            &__brac-study {
                grid-column: 10 / 13;
            }
            &__brac-study__btn {
                font-size: 1.4rem;
            }
            &__brac-study__btn__line {
                width: 49%;
            }
        }
    }
}


// 1131px
@media screen and (max-width: 1131px) {
    
    // PROJECT 1
    .section3-container .project-container1__fullyResponsive {
        grid-column: 6 / 10;
        grid-row: 1 / 2;
        align-self: end;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    .section3-container .project-container1__tool-stack {
        grid-row: 1 / 2;
        grid-column: 10 / 13;
        padding-left: 3rem;
        font-size: 2.2rem;
        &__my-role {
            font-size: 1.5rem;
            padding-left: 3rem;
        }
        &__list {
            grid-row: 2 / 6;
            grid-column: 10 / 13;
            padding-left: 3rem;
        }
    }
    // PROJECT 2
    .section3-container .project-container2__tool-stack {
        grid-row: 1 / 2;
        grid-column: 10 / 13;
        margin-right: -3rem;
        font-size: 2.2rem;
        &__my-role {
            font-size: 1.5rem;
            padding-left: 3rem;
        }
        &__list {
            grid-row: 2 / 6;
            grid-column: 10 / 13;
            padding-left: 3rem;
        }
    }
    // PROJECT 3
    .section3-container .project-container3__tool-stack {
        grid-row: 1 / 2;
        grid-column: 10 / 13;
        margin-right: -3rem;
        font-size: 2.2rem;
        &__my-role {
            font-size: 1.5rem;
            padding-left: 3rem;
        }
        &__list {
            grid-row: 2 / 6;
            grid-column: 10 / 13;
            padding-left: 3rem;
        }
    }
    // PROJECT 4
    .section3-container .project-container4__tool-stack {
        grid-row: 1 / 2;
        grid-column: 10 / 13;
        margin-right: -3rem;
        font-size: 2.2rem;
        &__my-role {
            font-size: 1.5rem;
            padding-left: 3rem;
        }
        &__list {
            grid-row: 2 / 6;
            grid-column: 10 / 13;
            padding-left: 3rem;
        }
    }
    // PROJECT 5
    .section3-container .project-container5__tool-stack5 {
        grid-row: 2 / 3;
        grid-column: 10 / 13;
        margin-right: -3rem;
        font-size: 2.2rem;
        &__my-role5 {
            font-size: 1.5rem;
            padding-left: 3rem;
        }
        &__list {
            grid-row: 3 / 4;
            grid-column: 10 / 13;
            padding-left: 3rem;
        }
    }

    .section3-container .project-container2__equalLine1 {
        height: 8.5%;
    }
    .section3-container .project-container2__equalLine2 {
        height: 8.5%;
    }
    .section3-container .project-container3__equalLine1 {
        height: 8.5%;
    }
    .section3-container .project-container3__equalLine2 {
        height: 8.5%;
    }
    .section3-container .project-container4__equalLine1 {
        height: 8.5%;
    }
    .section3-container .project-container4__equalLine2 {
        height: 8.5%;
    }
}

// 1107
@media screen and (max-width: 1107px) {
    .section3-container .project-container2 {

        &__hand {
            width: 3rem;
            grid-column: 10 / 13;
            margin-bottom: -2rem;
        }
    }
}

// 1100
@media screen and (max-width: 1100px) {
    .section3-container .project-container1__box1 {
        grid-row: 2 / 4;
        margin-left: -4.5rem;
        &__devPortfolio {
            width: 110%;
        }
    }
}


// 1055px
@media screen and (max-width: 1055px) {
    .section3-container .project-container5__box1__HighFidel1 {
            width: 13rem;
    } 
    .section3-container .project-container5__box2__HighFidel2 {
        width: 13rem;
    } 
    .section3-container .project-container5__box3__BCMicon {
        width: 13rem;
    }
    // .section3-container .project-container5__box1__HighFidel1 {
    //     width: 10rem;
    // } 
    // .section3-container .project-container5__box1__HighFidel1 {
    //     width: 10rem;
    // }           
    .section3-container {

        // PROJECT 1
        .project-container1 {
            grid-row: 3 / 7;
            grid-column: 2 / 6;
    
            &__fullyResponsive {
                margin-top: 0rem;
                grid-column: 6 / 9;
            }
        }

        // PROJECT 4
        .project-container4 {
            &__fullyResponsive {
                margin-top: 0rem;
                grid-column: 6 / 9;
            }
        }

        // PROJECT 5
        .project-container5 {
            
            &__high-fidelity {
                margin-top: 0rem;
                grid-column: 6 / 9;
            }
            &__project-text1 {
                grid-column: 2 / 10;
            }
        }

        .project-container2__equalLine1 {
            height: 7%;
        }
        .project-container2__equalLine2 {
            height: 7%;
        }
        .project-container3__equalLine1 {
            height: 7%;
        }
        .project-container3__equalLine2 {
            height: 7%;
        }
        .project-container4__equalLine1 {
            height: 7%;
        }
        .project-container4__equalLine2 {
            height: 7%;
        }
    }

}


// 900px
@media screen and (max-width: 900px) {
    .section3-container .projects-header {
        font-size: 5.5rem;
    }

        // PROJECT 1   

    .section3-container .project-container1 {
        grid-row: 3 / 7;
        grid-column: 2 / 6;

        &__fullyResponsive {
            grid-column: 6 / 9;
            font-size: 1.6rem;
            margin-top: 0rem;
        }

        &__project-title1 {
            font-size: 2rem;
        }
        &__project-title2 {
            font-size: 2rem;
        }
        &__project-text1 {
            font-size: 1.4rem;
        }
        &__project-text2 {
            font-size: 1.4rem;
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .1rem;
            height: 20rem;
        }

        &__breakLine1 {
            width: 90%;
        }
        &__breakLine2 {
            grid-column: 7 / 14;
            width: 90%;
        }
        &__lineBreak {
            font-size: 5rem;
            margin-bottom: -3rem;

        }
    }
    
    // PROJECT 2
    .section3-container .project-container2 {
        grid-row: 8 / 12;
        grid-column: 2 / 6;

        &__project-title1 {
            font-size: 2rem;
        }
        &__project-title2 {
            font-size: 2rem;
        }
        &__project-text1 {
            font-size: 1.4rem;
        }
        &__project-text2 {
            font-size: 1.4rem;
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .1rem;
            height: 20rem;
        }

        &__breakLine1 {
            grid-column: 1 / 5;
            width: 60%;
        }
        &__breakLine2 {
            grid-column: 6 / 14;
            width: 80%;
        }
        &__lineBreak {
            font-size: 5rem;
            margin-bottom: -3rem;
        }
    }

    // PROJECT 3
    .section3-container .project-container3 {
        grid-row: 13 / 17;
        grid-column: 2 / 6;

        &__project-title1 {
            font-size: 2rem;
        }
        &__project-title2 {
            font-size: 2rem;
        }
        &__project-text1 {
            font-size: 1.4rem;
        }
        &__project-text2 {
            font-size: 1.4rem;
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .1rem;
            height: 20rem;
        }

        &__breakLine1 {
            grid-column: 1 / 5;
            width: 60%;
        }
        &__breakLine2 {
            grid-column: 6 / 14;
            width: 80%;
        }
        &__lineBreak {
            font-size: 5rem;
            margin-bottom: -3rem;
        }
    }

    // PROJECT 4
    .section3-container .project-container4 {
        grid-row: 18 / 22;
        grid-column: 2 / 6;

        &__fullyResponsive {
            grid-column: 6 / 9;
            font-size: 1.6rem;
        }

        &__project-title1 {
            font-size: 2rem;
        }
        &__project-title2 {
            font-size: 2rem;
        }
        &__project-text1 {
            font-size: 1.4rem;
        }
        &__project-text2 {
            font-size: 1.4rem;
        }
        &__project-text-line {
            grid-column: 5 / 6;
            grid-row: 4 / 7;
            width: .1rem;
            height: 20rem;
        }

        &__breakLine1 {
            grid-column: 1 / 5;
            width: 60%;
        }
        &__breakLine2 {
            grid-column: 6 / 14;
            width: 80%;
        }
        &__lineBreak {
            font-size: 5rem;
            margin-bottom: -3rem;
        }
    }

    // PROJECT 5
    .section3-container .project-container5__box1__HighFidel1 {
        width: 12rem;
    } 
    .section3-container .project-container5__box2__HighFidel2 {
        width: 12rem;
    } 
    .section3-container .project-container5__box3__BCMicon {
        width: 12rem;
    }
    .section3-container .project-container5 {
        grid-row: 23 / 27;
        grid-column: 2 / 6;

        &__high-fidelity {
            grid-column: 6 / 9;
            font-size: 1.6rem;
        }

        &__project-title5 { 
        }

        &__project-text5 {
            font-size: 1.6rem;
        }
    }
}


// 830px
@media screen and (max-width: 830px) {

    // PROJECT 1
    .section3-container .project-container1__fullyResponsive {
        margin-left: -3rem;
    }
    .section3-container .project-container1__tool-stack {
        padding-left: 3rem;
        &__list {
            padding-left: 10rem;
        }
    }

    .section3-container .white-background {
        display: none;
    }

    .section3-container .black-background {
        width: 85%;
    }

    
    // PROJECT 2 
    .section3-container .project-container2__tool-stack {
        padding-left: 3rem;
        &__list {
            padding-left: 10rem;
        }
    }

    // PROJECT 3 
    .section3-container .project-container3__tool-stack {
        padding-left: 3rem;
        &__list {
            padding-left: 10rem;
        }
    }

    // PROJECT 4
    .section3-container .project-container4__tool-stack {
        padding-left: 3rem;
        &__list {
            padding-left: 10rem;
        }
    }

    // PROJECT 5
    .section3-container .project-container5__tool-stack {
        padding-left: 4rem;
        &__list {
            padding-left: 5rem;
        }
    }
}

// 775px
@media screen and (max-width: 775px) {

    .section3-container .projects-header {
        font-size: 4.5rem;
        grid-column: 1 / 4;
        padding-left: 3rem;
    }

    .section3-container .project-container1 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            font-size: 1.5rem;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                grid-row: 6 / 7;
                grid-column: 1 / 5;
                font-size: 1.4rem;
                margin-top: 0rem;
                align-self: end;
                padding-top: 0rem;
                padding-bottom: 1rem;
                margin-left: -3rem;
                justify-self: start;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 1 / 2;
                    grid-column: 4 / 5;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--8 {
                    grid-row: 2 / 3;
                    grid-column: 4 / 5;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
            }
        }

        &__fullyResponsive {
            grid-column: 7 / 11;
            grid-row: 1 / 2;
            align-self: start;
            margin-top: -3rem;
        }

        &__box1 {
            grid-column: 1 / 10;
            grid-row: 1 / 3;
            margin-left: 0rem;
        }

        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 1rem;
            text-align: start;
        }
        &__project-text-line {
            display: none;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
    }

    // PROJECT 2
    .section3-container .project-container2 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            font-size: 1.5rem;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                grid-row: 6 / 7;
                grid-column: 1 / 5;
                font-size: 1.4rem;
                margin-top: 0rem;
                align-self: end;
                padding-top: 0rem;
                padding-bottom: 1rem;
                margin-left: -3rem;
                justify-self: start;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 1 / 2;
                    grid-column: 4 / 5;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--8 {
                    grid-row: 2 / 3;
                    grid-column: 4 / 5;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
            }
        }

        &__fullyResponsive {
            grid-column: 7 / 11;
            grid-row: 1 / 2;
            align-self: start;
            margin-top: -3rem;
        }

        &__box1 {
            grid-column: 1 / 5;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__box2 {
            grid-column: 6 / 11;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 4.5%;
        }
        &__equalLine2 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 5%;
        }
        &__hand {
            grid-row: 4 / 5;
            margin-bottom: -2rem;
            align-self: end;
        }
        &__brac-study {
            grid-row: 5 / 6;
        }

        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 1rem;
            text-align: start;
        }
        &__project-text-line {
            display: none;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        } 
    }

    // PROJECT 3
    .section3-container .project-container3 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            font-size: 1.5rem;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                grid-row: 6 / 7;
                grid-column: 1 / 5;
                font-size: 1.4rem;
                margin-top: 0rem;
                align-self: end;
                padding-top: 0rem;
                padding-bottom: 1rem;
                margin-left: -3rem;
                justify-self: start;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 1 / 2;
                    grid-column: 4 / 5;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--8 {
                    grid-row: 2 / 3;
                    grid-column: 4 / 5;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
            }
        }

        &__fullyResponsive {
            grid-column: 7 / 11;
            grid-row: 1 / 2;
            align-self: start;
            margin-top: -3rem;
        }

        &__box1 {
            grid-column: 1 / 5;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__box2 {
            grid-column: 6 / 11;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 4.5%;
        }
        &__equalLine2 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 5%;
        }
        &__hand {
            grid-row: 4 / 5;
            margin-bottom: -2rem;
            align-self: end;
        }
        &__brac-study {
            grid-row: 5 / 6;
        }

        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 1rem;
            text-align: start;
        }
        &__project-text-line {
            display: none;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        } 
    }

    // PROJECT 4
    .section3-container .project-container4 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            font-size: 1.5rem;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                grid-row: 6 / 7;
                grid-column: 1 / 5;
                font-size: 1.4rem;
                margin-top: 0rem;
                align-self: end;
                padding-top: 0rem;
                padding-bottom: 1rem;
                margin-left: -3rem;
                justify-self: start;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 1 / 2;
                    grid-column: 4 / 5;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--8 {
                    grid-row: 2 / 3;
                    grid-column: 4 / 5;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
            }
        }

        &__fullyResponsive {
            grid-column: 7 / 11;
            grid-row: 1 / 2;
            align-self: start;
            margin-top: -3rem;
        }

        &__box1 {
            grid-column: 1 / 5;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__box2 {
            grid-column: 6 / 11;
            grid-row: 2 / 3;
            margin-left: 0rem;
        }
        &__equalLine1 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 4.5%;
        }
        &__equalLine2 {
            grid-row: 2 / 3;
            grid-column: 5 / 6;
            align-self: center;
            height: 5%;
        }
        &__hand {
            grid-row: 4 / 5;
            margin-bottom: -2rem;
            align-self: end;
        }
        &__brac-study {
            grid-row: 5 / 6;
        }

        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 1rem;
            text-align: start;
        }
        &__project-text-line {
            display: none;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.4rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
    }

    // PROJECT 5
    .section3-container .project-container5 {

        &__tool-stack5 {
            grid-row: 8 / 9;
            grid-column: 1 / 5;
            padding-left: 0rem;
            font-size: 1.5rem;
            align-self: start;
            margin-top: 1.7rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role5 {
                grid-row: 8 / 9;
                grid-column: 1 / 5;
                margin-top: -.6rem;
                align-self: end;
                padding-top: 0rem;
                padding-bottom: 1rem;
                margin-left: -3rem;
                justify-self: start;
            }
            &__list {
                font-size: 1.5rem;
                padding-left: 0rem;
                grid-row: 9 / 10;
                grid-column: 1 / 13;
                margin-right: 0rem;
                align-self: end;

                display: grid;
                grid-template-rows: repeat(3, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }
            }
        }

        &__before {
            align-self: start;
        }
        &__after {
            align-self: start;
        }

        &__box1 {
            grid-column: 1 / 5;
            grid-row: 1 / 4;
            margin-left: 0rem;
            align-self: end;

            &__HighFidel1 {
                width: 10rem;
            }
        }
        &__box2 {
            grid-row: 1 / 4;
            grid-column: 4 / 8;
            align-self: end;

            &__HighFidel2 {
                width: 10rem;
            }
        }
        &__box3 {
            grid-row: 1 / 4;
            grid-column: 7 / 11;
            align-self: end;

            &__BCMicon {
                width: 10rem;
            }
        }
        &__box4 {
            grid-row: 4 / 6;
            grid-column: 1 / 7;
            width: 25rem;
            align-self: center;

            &__BracDesign {
                width: 100%;
            }
        }


        &__project-title1 {
            font-size: 2rem;
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            align-self: start;
            justify-self: start;
        }
        &__high-fidelity {
           grid-column: 7 / 11;
           grid-row: 1 / 2;
           align-self: center;     
        }

        &__project-text1 {
            font-size: 1.4rem;
            grid-row: 6 / 8;
            grid-column: 1 / 10;
            align-self: end;
            text-align: start;
        }
    }
}

// 738px
@media screen and (max-width: 738px) {
    // PROJECT 2
    .section3-container .project-container2 {
        &__hand {
            grid-row: 4 / 5;
            margin-bottom: 0rem;
            align-self: end;
        }
    }
}

// 725px
@media screen and (max-width: 725px) {
    // PROJECT 2
    .section3-container .project-container2 {
        &__hand {
            grid-row: 4 / 5;
            margin-bottom: -2rem;
            align-self: center;
        }
    }
}

// 660px
@media screen and (max-width: 660px) {
    // PROJECT 1
    .section3-container .project-container1 {

        &__breakLine1 {
            width: 70%;
        }
        &__breakLine2 {
            width: 75%;
        }
        &__lineBreak .br {
            font-size: 4.5rem;
        }
    }
    // PROJECT 2
    .section3-container .project-container2 {

        &__breakLine1 {
            width: 48%;
        }
        &__breakLine2 {
            width: 75%;
        }
        &__lineBreak .br {
            font-size: 4.5rem;
        }
    }
    // PROJECT 3
    .section3-container .project-container3 {

        &__breakLine1 {
            width: 48%;
        }
        &__breakLine2 {
            width: 75%;
        }
        &__lineBreak .br {
            font-size: 4.5rem;
        }
    }
    // PROJECT 4
    .section3-container .project-container4 {

        &__breakLine1 {
            width: 48%;
        }
        &__breakLine2 {
            width: 75%;
        }
        &__lineBreak .br {
            font-size: 4.5rem;
        }
    }
}


// 645px
@media screen and (max-width: 645px) {
    
    // PROJECT 3
    .section3-container .project-container3 {

        &__project-title3 {
            padding-bottom: 2rem;
        }
        &__project-text3 {
            font-size: 1.6rem;
            grid-row: 3 / 4;
            grid-column: 1 / 10;
            align-self: start;
            text-align: start;
        }
    }
    // PROJECT 4
    .section3-container .project-container4 {

        &__project-title4 {
            padding-bottom: 2rem;
        }
        &__project-text4 {
            font-size: 1.6rem;
            grid-row: 3 / 4;
            grid-column: 1 / 10;
            align-self: start;
            text-align: start;
        }
    }
    // PROJECT 5
    .section3-container .project-container5__box1__HighFidel1 {
        width: 9rem;
    } 
    .section3-container .project-container5__box2__HighFidel2 {
        width: 9rem;
    } 
    .section3-container .project-container5__box3__BCMicon {
        width: 9rem;
    }
    .section3-container .project-container5__box4 {
        width: 25rem;
    }
}


// 625px
@media screen and (max-width: 625px) {

    .section3-container .black-background {
        width: 80%;
    }

    .section3-container .projects-header {
        font-size: 3.5rem;
    }

    .section3-container .project-container1 {

        &__breakLine1 {
            width: 75%;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 12;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }
}


// 615px
@media screen and (max-width: 615px) {

    .section3-container .black-background {
        width: 100%;
    }
}

// 550px
@media screen and (max-width: 550px) {
    
    // PROJECT 1
    .section3-container .project-container1 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                padding-bottom: 0rem;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--7 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 3 / 4;
                }
            }
        }
        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 11;
            font-size: 1.3rem;
            align-self: end;
            margin-bottom: 4rem;
            text-align: start;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 11;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
        &__breakLine1 {
            height: .6rem;
        }
        &__breakLine2 {
            width: 80%;
            height: .6rem;
            grid-column: 7 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 2
    .section3-container .project-container2 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                padding-bottom: 0rem;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--7 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 3 / 4;
                }
            }
        }
        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: end;
            margin-bottom: 4rem;
            text-align: start;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
        &__breakLine1 {
            width: 125%;
            height: .6rem;
            grid-column: 1 / 4;
        }
        &__breakLine2 {
            width: 120%;
            height: .6rem;
            grid-column: 7 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 3
    .section3-container .project-container3 {

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                padding-bottom: 0rem;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--6 {
                    grid-row: 2 / 3;
                    grid-column: 3 / 4;
                }

                &__item--7 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 3 / 4;
                    grid-column: 3 / 4;
                }
            }
        }
        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: end;
            margin-bottom: 4rem;
            text-align: start;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
        &__breakLine1 {
            width: 125%;
            height: .6rem;
            grid-column: 1 / 4;
        }
        &__breakLine2 {
            width: 120%;
            height: .6rem;
            grid-column: 7 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 4
    .section3-container .project-container4 {
        &__fullyResponsive {
            margin-right: 5rem;
        }

        &__tool-stack {
            grid-row: 6 / 7;
            grid-column: 1 / 5;
            padding-left: 0rem;
            align-self: center;
            margin-top: 2rem;
            margin-right: 0rem;
            justify-self: start;
            &__my-role {
                padding-bottom: 0rem;
            }
            &__list {
                font-size: 1.6rem;
                padding-left: 0rem;
                grid-row: 7 / 8;
                grid-column: 1 / 13;
                margin-right: 0rem;

                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(4, 1fr);
                column-gap: 4rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 1 / 2;
                    grid-column: 3 / 4;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--5 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

            }
        }
        &__project-title1 {
            font-size: 2rem;
            grid-row: 3 / 4;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: start;
            justify-self: start;
        }
        &__project-title2 {
            font-size: 2rem;
            grid-row: 4 / 5;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: end;
            margin-bottom: 4rem;
            text-align: start;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 10;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
        &__breakLine1 {
            width: 125%;
            height: .6rem;
            grid-column: 1 / 4;
        }
        &__breakLine2 {
            width: 120%;
            height: .6rem;
            grid-column: 7 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    //PROJECT 5
    .section3-container .project-container5 {

        &__project-text1 {
            grid-column: 1 / 11;
        }
    }
}


// 485px
@media screen and (max-width: 485px) {

    .section3-container .projects-header {
        font-size: 3.5rem;
        padding-left: 0rem;
    }

    // PROJECT 1
    .section3-container .project-container1 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__fullyResponsive {
            margin-top: -1rem;     
         }
        &__project-title1 {
            grid-row: 2 / 3;
            grid-column: 1 / 5;
            font-size: 1.7rem;
            align-self: end;
            justify-self: start;
            margin-bottom: -.5rem;
        }

        &__project-text1 {
            grid-row: 3 / 5;
            grid-column: 1 / 12;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 4rem;
            text-align: start;
        }
        &__project-text2 {
            grid-row: 5 / 7;
            grid-column: 1 / 12;
            font-size: 1.3rem;
            align-self: center;
            margin-bottom: 5rem;
            text-align: start;
        }
        &__breakLine1 {
            width: 65%;
            height: .6rem;
        }
        &__breakLine2 {
            width: 100%;
            height: .6rem;
            grid-column: 8 / 12;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 2
    .section3-container .project-container2 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__before {
            grid-column: 2 / 5;
        }
        &__after {
            grid-column: 7 / 10;
        }

        &__box1 {
            width: 12rem;
        }
        &__box2 {
            grid-column: 7 / 12;
            width: 12rem;
        }
        &__equalLine1 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-left: 0rem;
            margin-bottom: 1rem;
            height: 5%;
            width: 30%;
        }
        &__equalLine2 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-top: 1rem;
            margin-left: 0rem;
            width: 30%;
        }
        &__project-text1 {
            grid-column: 1 / 12;
        }
        &__tool-stack {
            padding-left: 0rem;
        }

        &__breakLine1 {
            width: 60%;
            margin-bottom: -3rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -3rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -6rem;
        }
    }

    // PROJECT 3
    .section3-container .project-container3 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__before {
            grid-column: 2 / 5;
        }
        &__after {
            grid-column: 7 / 10;
        }

        &__box1 {
            width: 12rem;
        }
        &__box2 {
            grid-column: 7 / 12;
            width: 12rem;
        }
        &__equalLine1 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-left: 0rem;
            margin-bottom: 1rem;
            height: 5%;
            width: 30%;
        }
        &__equalLine2 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-top: 1rem;
            margin-left: 0rem;
            width: 30%;
        }
        &__project-text1 {
            grid-column: 1 / 12;
        }
        &__tool-stack {
            padding-left: 0rem;
        }

        &__breakLine1 {
            width: 60%;
            margin-bottom: -3rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -3rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -6rem;
        }
    }

    // PROJECT 4
    .section3-container .project-container4 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__fullyResponsive {
            grid-column: 6 / 13;
            margin-top: 0rem;
        }
        &__before {
            grid-column: 2 / 5;
        }
        &__after {
            grid-column: 7 / 10;
        }

        &__box1 {
            width: 12rem;
        }
        &__box2 {
            grid-column: 7 / 12;
            width: 12rem;
        }
        &__equalLine1 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-left: 0rem;
            margin-bottom: 1rem;
            height: 5%;
            width: 30%;
        }
        &__equalLine2 {
            grid-column: 5 / 7;
            justify-self: center;
            margin-top: 1rem;
            margin-left: 0rem;
            width: 30%;
        }
        &__project-text1 {
            grid-column: 1 / 12;
        }
        &__tool-stack {
            padding-left: 0rem;
        }

        &__breakLine1 {
            width: 60%;
            margin-bottom: -3rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -3rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -6rem;
        }
    }

    // PROJECT 5
    .section3-container .project-container5 {
        margin-left: 4rem;
        grid-column: 1 / 5;

        &__box1 {
            align-self: center;
        }
        &__box2 {
            align-self: center;
        }
        &__box3 {
            align-self: center;
        }
        &__box4 {
            align-self: start;
        }
        &__project-title1 {
            grid-row: 5 / 6;
            align-self: end;
        }
        &__project-text1 {
            grid-column: 1 / 11;
        }
        &__tool-stack {
            padding-left: 0rem;
            &__list {
                font-size: 1.6rem;
                padding-left: 1rem;
            }
        }
    }
}


// 400px
@media screen and (max-width: 400px) {

    .section3-container .projects-header {
        font-size: 3.5rem;
    }

    // PROJECT 1
    .section3-container .project-container1 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__tool-stack {
        
            &__list {
                padding-left: 1rem;
            }
        }

        // &__fullyResponsive {
        //     grid-column: 4 / 9;
        // }

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 2
    .section3-container .project-container2 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__tool-stack {
        
            &__list {
                padding-left: 0rem;
            }
        }

        // &__fullyResponsive {
        //     grid-column: 4 / 9;
        // }
        &__hand {
            grid-row: 5 / 6;
            grid-column: 9 / 12;
            align-self: start;
        }
        &__brac-study {
            grid-row: 5 / 7;
            grid-column: 9 / 12;
            align-self: center;
            justify-self: start;
        }

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 3
    .section3-container .project-container3 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__tool-stack {
        
            &__list {
                padding-left: 0rem;
            }
        }

        // &__fullyResponsive {
        //     grid-column: 4 / 9;
        // }
        &__project-text2 {
            grid-column: 1 / 12;
        }

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 4
    .section3-container .project-container4 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__tool-stack {
        
            &__list {
                padding-left: 0rem;
            }
        }

        // &__fullyResponsive {
        //     grid-column: 5 / 9;
        // }
        &__project-text2 {
            grid-column: 1 / 12;
        }

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    // PROJECT 5
    .section3-container .project-container5 {
        margin-left: 5rem;
        grid-column: 1 / 5;

        &__tool-stack {
        
            &__list {
                padding-left: 0rem;
            }
        }

        &__high-fidelity {
            grid-column: 6 / 9;
        }

        // &__breakLine1 {
        //     width: 50%;
        // }
        // &__breakLine2 {
        //     width: 100%;
        //     grid-column: 8 / 11;
        // }
        // &__lineBreak {
        //     grid-column: 4 / 7;
        // }
    }
}


// 380px
@media screen and (max-width: 380px) {

    // PROJECT 1
    .section3-container .project-container1 {
        margin-left: 3rem;

        &__project-text1 {
            grid-column: 1 / 10;
        }
        &__project-text2 {
            grid-column: 1 / 10;
        }
        &__tool-stack {
            padding-left: 0rem;
            &__list {
               
                display: grid;
                grid-template-rows: repeat(4, 1fr);
                grid-template-columns: repeat(4, 1fr);

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--5 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 4 / 5;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 4 / 5;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 5 / 6;
                    grid-column: 1 / 2;
                }
            }
        }

        &__breakLine1 {
            width: 70%;
            margin-bottom: -5rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -5rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -8rem;
        }
    }


    // PROJECT 2
    .section3-container .project-container2 {
        margin-left: 3rem;

        &__box2 {
            grid-column: 6 / 10;
        }
        &__equalLine1 {
            grid-column: 5 / 6;
            align-self: center;
            margin-left: 0rem;
        }
        &__equalLine2 {
            grid-column: 5 / 6;
            align-self: center;
        }
        &__project-title1 {
            align-self: start;
            margin-top: -1rem;
        }
        &__project-text1 {
            grid-column: 1 / 10;
        }
        &__project-text2 {
            grid-column: 1 / 10;
        }
        &__hand {
            grid-row: 6 / 7;
            grid-column: 8 / 11;
            align-self: end;
            margin-bottom: -2rem;
        }
        &__brac-study {
            grid-row: 7 / 8;
            grid-column: 8 / 11;
            align-self: center;
        }

        &__tool-stack {
            padding-left: 0rem;
            &__list {
               
                display: grid;
                grid-template-rows: repeat(6, 1fr);
                grid-template-columns: repeat(4, 1fr);
                padding-left: 1rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--5 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 4 / 5;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 4 / 5;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 5 / 6;
                    grid-column: 1 / 2;
                }

                &__item--10 {
                    grid-row: 5 / 6;
                    grid-column: 2 / 3;
                }

                &__item--11 {
                    grid-row: 6 / 7;
                    grid-column: 1 / 2;
                }
            }
        }
        &__breakLine1 {
            width: 70%;
            margin-bottom: -5rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -5rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -8rem;
        }
    }


    // PROJECT 3
    .section3-container .project-container3 {
        margin-left: 3rem;

        &__box2 {
            grid-column: 6 / 10;
        }
        &__equalLine1 {
            grid-column: 5 / 6;
            align-self: center;
            margin-left: 0rem;
        }
        &__equalLine2 {
            grid-column: 5 / 6;
            align-self: center;
        }
        &__project-title1 {
            align-self: start;
            margin-top: -1rem;
        }
        &__project-text1 {
            grid-column: 1 / 10;
        }
        &__project-text2 {
            grid-column: 1 / 10;
        }

        &__tool-stack {
            padding-left: 2rem;
            &__list {
               
                display: grid;
                grid-template-rows: repeat(6, 1fr);
                grid-template-columns: repeat(4, 1fr);
                padding-left: 1rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--5 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }

                &__item--6 {
                    grid-row: 3 / 4;
                    grid-column: 2 / 3;
                }

                &__item--7 {
                    grid-row: 4 / 5;
                    grid-column: 1 / 2;
                }

                &__item--8 {
                    grid-row: 4 / 5;
                    grid-column: 2 / 3;
                }

                &__item--9 {
                    grid-row: 5 / 6;
                    grid-column: 1 / 2;
                }

                &__item--10 {
                    grid-row: 5 / 6;
                    grid-column: 2 / 3;
                }

                &__item--11 {
                    grid-row: 6 / 7;
                    grid-column: 1 / 2;
                }
            }
        }
        &__breakLine1 {
            width: 70%;
            margin-bottom: -5rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -5rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -8rem;
        }
    }


    // PROJECT 4
    .section3-container .project-container4 {
        margin-left: 2.5rem;

        &__box2 {
            grid-column: 6 / 10;
        }
        &__equalLine1 {
            grid-column: 5 / 6;
            align-self: center;
            margin-left: 0rem;
        }
        &__equalLine2 {
            grid-column: 5 / 6;
            align-self: center;
        }
        &__project-title1 {
            align-self: start;
            margin-top: -1rem;
        }
        &__project-text1 {
            grid-column: 1 / 10;
        }
        &__project-text2 {
            grid-column: 1 / 10;
        }

        &__tool-stack {
            padding-left: 1rem;
            &__list {
               padding-left: 1rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }

                &__item--3 {
                    grid-row: 2 / 3;
                    grid-column: 1 / 2;
                }

                &__item--4 {
                    grid-row: 2 / 3;
                    grid-column: 2 / 3;
                }

                &__item--5 {
                    grid-row: 3 / 4;
                    grid-column: 1 / 2;
                }
            }
        }
        &__breakLine1 {
            width: 70%;
            margin-bottom: -5rem;
        }
        &__breakLine2 {
            width: 100%;
            grid-column: 8 / 11;
            margin-bottom: -5rem;
        }
        &__lineBreak {
            grid-column: 4 / 7;
            margin-bottom: -8rem;
        }
    }


    // PROJECT 5
    .section3-container .project-container5 {
        margin-left: 2.5rem;

        &__box4 {
            align-self: start;
            margin-top: -2rem;
        }
        &__project-title1 {
            grid-row: 5 / 6;
            align-self: center;
            margin-top: 1rem;
        }
        &__project-text1 {
            grid-column: 1 / 10;
        }
        &__tool-stack {
            padding-left: 0rem;
            &__list {
               padding-left: 1rem;

                &__item--1 {
                    grid-row: 1 / 2;
                    grid-column: 1 / 2;
                }

                &__item--2 {
                    grid-row: 1 / 2;
                    grid-column: 2 / 3;
                }
            }
        }
    }
}


// 360px
@media screen and (max-width: 360px) {

    .section3-container .project-container1 {

        &__fullyResponsive {
            grid-column: 6 / 10;
        }
    }

    .section3-container .project-container2 {

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            grid-column: 8 / 10;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    .section3-container .project-container3 {

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            grid-column: 8 / 10;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }

    .section3-container .project-container4 {

        &__breakLine1 {
            width: 50%;
        }
        &__breakLine2 {
            grid-column: 8 / 10;
        }
        &__lineBreak {
            grid-column: 4 / 7;
        }
    }
}



///////////////////////////  DEVICES  //////////////////////////////
//////////////////////////////////////////////////////////////////


// Portrait
// iPhone XR
@media screen and (max-height: 919px) and (orientation: portrait) {
    .section3-container .project-container4__fullyResponsive {
        margin-left: -2rem;
    }
    .section3-container .project-container1__tool-stack__list {
        font-size: 1.4rem;
    }
    .section3-container .project-container2__tool-stack__list {
        font-size: 1.4rem;
    }
    .section3-container .project-container3__tool-stack__list {
        font-size: 1.4rem;
    }
    .section3-container .project-container4__tool-stack__list {
        font-size: 1.4rem;
    }
    .section3-container .project-container5__tool-stack__list {
        font-size: 1.4rem;
    }
}


//////////////////////////////////////////////

// Landscape
// For The Nexus 7 / And Others...
@media screen and (max-height: 600px) and (orientation: landscape) {
    .section3-container {
        grid-row: 5 / 6;
    }
}

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 428px) and (max-width: 926px) and (orientation: landscape) {

    .section3-container {
        grid-row: 5 / 6;
    }
}

// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (max-width: 896px) and (orientation: landscape) {

    .section3-container {
        grid-row: 5 / 6;
    }
}

// Landscape
// Galaxy S9
@media screen and (max-height: 360px) and (max-width: 740px) {
    
    .section3-container {
        grid-row: 5 / 6;
    }
    
} 

// Landscape
// Galaxy Note 3
@media screen and (max-height: 360px) and (max-width: 640px){

    .section3-container {
        grid-row: 5 / 6;
    }
} 