.required {
    color: var(--my-red);
    margin-left: 5px;
    text-decoration: none;
}

.contact-container {
    grid-row: 1 / 6;
    grid-column: 1 / 7;
    width: 75%;
    height: 78%;
    justify-self: center;
    align-self: center;
    background-color: var(--my-background2);

    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .white-line1 {
        grid-column: 1 / 8;
        grid-row: 1 / 2;
        background: linear-gradient(to left, rgba(194, 190, 190, 0.2), #fff);
        height: 5%;
        width: 95%;
        justify-self: end;
        align-self: center;
    }
    .white-line2 {
        grid-column: 4 / 8;
        grid-row: 1 / 2;
        background: linear-gradient(to left, rgba(194, 190, 190, 0.2), #fff);
        height: 5%;
        width: 85%;
        justify-self: end;
        align-self: end;
    }

    .email-instruction {
        grid-column: 7 / 8;
        grid-row: 2 / 5;
        align-self: center;
        justify-self: end;
        margin-right: 4rem;
        writing-mode: vertical-rl;
        text-orientation: mixed;
        
    }

    .header-contact {
        grid-row: 1 / 3;
        grid-column: 1 / 5;
        font-size: 7rem;
        text-transform: uppercase;
        font-weight: 400;
        align-self: center;
        justify-self: center;
        opacity: 0;
        transition: opacity 1200ms ease-in;
    }

    .name {
        grid-row: 2 / 3;
        grid-column: 2 / 5;
        text-transform: capitalize;
        color: #000;
        font-weight: 600;
        align-self: center;
        margin-top: 2rem;
    }
    .input1 {
        grid-column: 2 / 4;
        grid-row: 2 / 3;
        align-self: end;
        height: 25%;
    }

    .email {
        grid-column: 5 / 6;
        grid-row: 2 / 3;
        text-transform: capitalize;
        color: #000;
        font-weight: 600;
        align-self: center;
        margin-top: 2rem;
    }
    .input2 {
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        align-self: end;
        height: 25%
    }

    .subject {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        text-transform: capitalize;
        color: #000;
        font-weight: 600;
        align-self: start;
        margin-top: 2rem;
    }
    .input3 {
        grid-column: 2 / 7;
        grid-row: 3 / 4;
        height: 25%;
        align-self: center;
    }

    .message {
        grid-column: 2 / 3;
        grid-row: 3 / 4;
        text-transform: capitalize;
        color: #000;
        font-weight: 600;
        align-self: end;
        margin-bottom: 1rem;
    }
    .chars-box {
        grid-column: 2 / 3;
        grid-row: 5 / 6;
        width: 35%;
        height: 20%;
        background: var(--my-background);
        align-self: center;
        justify-self: start;
        margin-top: 6rem;

        &-count {
            text-align: center;
        }

    }
    .input4 {
        grid-column: 2 / 7;
        grid-row: 4 / 6;
        height: 80%;
    }

    .submitBTN {
        grid-column: 1 / 2;
        grid-row: 5 / 6;
        width: 70%;
        height: 35%;
        justify-self: center;
        align-self: center;
        margin-bottom: 2.5rem;
        text-transform: capitalize;
        font-weight: 600;
        letter-spacing: .2rem;
        color: #fff;
        background: linear-gradient(to left, rgba(247, 102, 102, 0.1), var(--my-orange));
        box-shadow: .7rem .6rem 2.8rem .1rem rgba(0, 0, 0, 0.24);
        transition: 0.2s all;
        border: none;
        cursor: pointer;

        &:active {
            transform: scale(0.98);
            box-shadow: .3rem .2rem 2.2rem .1rem rgba(0, 0, 0, 0.24);
        }
    }
}

.error {
    &__name {
        grid-row: 2;
        grid-column: 2 / 4;
        align-self: start;
        justify-self: center;
        height: 100px;
        width: 200px;
        padding: 1rem;
        font-weight: bold;
        letter-spacing: .1rem;
        line-height: 2.2rem;
        color: var(--my-red);
        border: 3px solid gray;
        background: rgb(0, 0, 0);
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 20px;
            width: 0;
            border-top: 20px solid black;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
    &__email {
        grid-row: 2;
        grid-column: 5 / 7;
        align-self: start;
        justify-self: center;
        height: 100px;
        width: 200px;
        padding: 1rem;
        font-weight: bold;
        letter-spacing: .1rem;
        line-height: 2.2rem;
        color: var(--my-red);
        border: 3px solid gray;
        background: rgb(0, 0, 0);
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 20px;
            width: 0;
            border-top: 20px solid black;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
    &__subject {
        grid-row: 2 / 4;
        grid-column: 4 / 5;
        align-self: center;
        justify-self: center;
        height: 100px;
        width: 200px;
        padding: 1rem;
        font-weight: bold;
        letter-spacing: .1rem;
        color: var(--my-red);
        line-height: 2.2rem;
        border: 3px solid gray;
        background: rgb(0, 0, 0);
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 20px;
            width: 0;
            border-top: 20px solid black;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
    &__message {
        grid-row: 3 / 5;
        grid-column: 2 / 4;
        align-self: center;
        justify-self: center;
        margin-top: -1rem;
        height: 100px;
        width: 200px;
        padding: 1rem;
        font-weight: bold;
        letter-spacing: .1rem;
        color: var(--my-red);
        line-height: 2.2rem;
        border: 3px solid gray;
        background: rgb(0, 0, 0);
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 100%;
            left: 20px;
            width: 0;
            border-top: 20px solid black;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
        }
    }
}

.status-message {
    opacity: 0;
    grid-row: 4;
    grid-column: 1 / span 2;
    align-self: end;
    margin-left: 6rem;
    height: 100px;
    width: 200px;
    padding: 1rem;
    font-weight: bold;
    letter-spacing: .1rem;
    line-height: 2.2rem;
    border: 3px solid gray;
    background: rgb(0, 0, 0);
    position: relative;
  }
  .status-message:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 20px;
    width: 0;
    border-top: 20px solid black;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
  }

.success {
    opacity: 1;
    color: var(--my-green)
}

.failure {
    opacity: 1;
    color: red;
}

///// UP STAGS /////

.contact-stag-up {
    opacity: 0;
    transition: opacity 1200ms ease-in;
}
.contact-stag-up.contact-animate-up {
    animation: animationUp 1000ms;
    opacity: 1;
}
///// RIGHT /////

.contact-stag-right {
    opacity: 0;
    transition: opacity 1200ms ease-in;
}
.contact-stag-right.contact-animate-right {
    animation: animationRight 1200ms;
    opacity: 1;
}
///// LEFT /////

.header-contact.contact-animate-left {
    animation: animationLeft 1300ms;
    opacity: 1;
}




///// MEDIA QUERIES /////

// 1300px
@media screen and (max-width: 1300px) {

    .contact-container {

        & .header-contact {
            font-size: 5.8rem;
        }
        & .submitBTN {
            font-size: 1.1rem;
            letter-spacing: .1rem;
        }
    }
}


// 1051px
@media screen and (max-width: 1051px) {

    .contact-container {

        & .header-contact {
            font-size: 5.8rem;
        }
        & .white-line2 {
            width: 75%;
        }
        & .email-instruction {
            font-size: 1.5rem;
        }
        & .submitBTN {
            font-size: 1rem;
            letter-spacing: .1rem;
            height: 25%;
        }

        & .chars-box {
            width: 45%;
        }
    }
}


// 900px
@media screen and (max-width: 900px) {

    .contact-container {

        & .header-contact {
            font-size: 5.4rem;
        }
        & .white-line2 {
            width: 70%;
        }

        & .name {
            font-size: 1.5rem;
        }
        & .email {
            font-size: 1.5rem;
        }
        & .subject {
            font-size: 1.5rem;
        }
        & .message {
            font-size: 1.5rem;
        }

        & .submitBTN {
            font-size: 1rem;
            letter-spacing: .1rem;
            height: 25%;
            width: 100%;
        }
    }
}


// 830px 
@media screen and (max-width: 830px) {

    .section4-container .white-background {
        display: none;
    }

    .contact-container {

        & .header-contact {
            font-size: 5rem;
        }
    }
}


// 746px 
@media screen and (max-width: 746px) {

    .contact-container {

        & .header-contact {
            grid-column: 1 / 6;
            font-size: 5rem;
        }

        & .white-line2 {
            width: 54%;
        }

        & .chars-box {
            width: 60%;
        }
    }
}



// 665px
@media screen and (max-width: 665px) {

    .contact-container {

        & .email-instruction {
            font-size: 1.5rem;
            margin-right: 2rem;
        }
    }
}


// 640px
@media screen and (max-width: 640px) {

    .contact-container {

        & .header-contact {
            font-size: 4.3rem;
        }
        & .email-instruction {
            font-size: 1.5rem;
            margin-right: 1rem;
        }
    }
}


// 600px
@media screen and (max-width: 600px) {

    .contact-container {
        justify-self: start;
        margin-left: 5rem;

        & .header-contact {
            font-size: 4.3rem;
        }
        & .email-instruction {
            font-size: 1.5rem;
            margin-right: 1rem;
        }
    }
}

// 550px
@media screen and (max-width: 550px) {

    .contact-container {
        width: 65%;

        .subject { 
            margin-top: 1rem;
        }
        .submitBTN {
            grid-column: 1 / 3;
            align-self: end;
        }

        & .white-line1 {
            height: 4%;
            display: none;
        }
        & .white-line2 {
            height: 4%;
            align-self: center;
            margin-top: -2rem;
        }
        & .name {
            grid-row: 1 / 2;
            align-self: end;
        }

        & .input1 {
            height: 25%;
            width: 93%;
            grid-column: 2 / 7;
            align-self: start;
        }
        & .email {
            align-self: center;
            grid-column: 2 / 3;
        }
        & .input2 {
            height: 25%;
            width: 93%;
            grid-column: 2 / 7;
        }
        & .input3 {
            height: 25%;
            width: 93%;
        }
        & .input4 {
            height: 50%;
            width: 93%;
        }

        & .chars-box {
            width: 70%;
            height: 20%;
            grid-row: 5 / 6;
            margin-top: 0rem;
            align-self: start;
            justify-self: start;
        }
        & .header-contact {
            font-size: 3.5rem;
            grid-row: 1 / 2;
            margin-top: -2rem;
        }
    }
}

// 460px
@media screen and (max-width: 460px) {

    .contact-container {
        width: 57%;
        margin-left: 3rem;
    }
}


// 400px
@media screen and (max-width: 400px) {

    .contact-container {
        width: 57%;
        margin-left: 2rem;
    }
}

// 380px
@media screen and (max-width: 380px) {

    .contact-container {
        width: 50%;
        margin-left: 2rem;

        & .white-line2 {
            height: 4%;
            width: 40%;
        }
        & .email-instruction {
            grid-row: 2 / 6;
            font-size: 1.3rem;
            margin-right: 1rem;
        }
    }
}



//////////////////  DEVICES  /////////////////
/////////////////////////////////////////////

// Portrait
// iPhone 6/7/8 Plus
@media screen and (max-height: 736px) and (max-width: 414px) and (orientation: portrait) {

    .contact-container {
        .email-instruction {
            grid-row: 2 / 6;
            font-size: 1.5rem;
            margin-right: 1rem;
        }
    }
}

// Portrait
// iPhone 6/7/8
@media screen and (max-height: 667px) and (max-width: 375px) {
    .contact-container {
        margin-left: 3rem;
    }
    
}

////////////////////////////////////////////

// Landscape
@media screen and (max-height: 360px) and (max-width: 800px) {
    .contact-container {
        .email-instruction {
            grid-row: 2 / 6;
        }
    }
}

// Landscape
@media screen and (max-height: 360px) and (max-width: 740px) {
    .contact-container {
        .email-instruction {
            grid-row: 2 / 6;
        }
    }
}


// Landscape
@media screen and (max-width: 640px){
    @media screen and (max-height: 360px){
        .contact-container {
            // grid-row: 1 / 3;
            & .email-instruction {
                grid-row: 2 / 6;
                font-size: 1.3rem;
                margin-right: 1rem;
            }
        }
    }
} 