.footer {
    grid-row: 5 / 6;
    grid-column: 1 / 7;

    display: grid;
    background-color: #000;
    text-transform: capitalize;
    z-index: 54;
    
    &__text {
        place-self: center;

        display: flex;
    }
}







///// MEDIA QUERIES /////

// 595px
@media screen and (max-width: 595px) {
    .footer {
        &__text {
            font-size: 1.5rem;
            padding-right: 4rem;
        }
    }
}

// 550px
@media screen and (max-width: 550px) {
    .footer {
        &__text {
            font-size: 1.5rem;
            padding-right: 10rem;
        }
    }
}

// 460px
@media screen and (max-width: 460px) {
    .footer {
        &__text {
            font-size: 1.5rem;
            justify-self: start;
            margin-left: 2rem;
        }
    }
}

// 380px
@media screen and (max-width: 380px) {
    .footer {
        &__text {
            font-size: 1.5rem;
            justify-self: start;
            margin-left: .5rem;
            padding-right: 0rem;
        }
    }
}

///////////////////////////  DEVICES  //////////////////////////////
//////////////////////////////////////////////////////////////////


// Landscape
// For The Nexus 7 / And Others...
@media screen and (max-height: 600px) and (orientation: landscape) {
    .footer {
        grid-row: 8 / -1;
    }
}

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 428px) and (max-width: 926px) {

    .footer {
        grid-row: 8 / -1;
    }
}

// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (max-width: 896px) {

    .footer {
        grid-row: 8 / -1;
    }
}

// Landscape
// Galaxy S9
@media screen and (max-height: 360px) and (max-width: 740px) {
    .footer {
        grid-row: 8 / -1;
    }
}

// Landscape
// Galaxy Note 3
@media screen and (max-width: 640px){
    @media screen and (max-height: 360px){
        .footer {
            grid-row: 8 / -1;
        }
        
    }
} 