.yellowLine {
    grid-column: 1 / 7;
    grid-row: 1 / 2;
    background: var(--my-yellow);
    height: 3%;
    z-index: 52;
}


/////  SECTION 2 CONTANIER /////
///////////////////////////////
.section2-container {
    grid-row: 2 / 3;
    grid-column: full-start / full-end;
    background-color: var(--my-background2);
    z-index: 200;

    display: grid;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
    grid-template-rows: repeat(5, 1fr);



     // WHITE STYLING BARS

    .white-bgBar1 {
        grid-column: 1 / 7;
        grid-row: 1 / 2;
        height: 5%;
        width: 100%;
        background: linear-gradient(to left, rgba(194, 190, 190, 0.2), #fff 70%);
        align-self: center;
        z-index: 52;
    }
    .white-bgBar2 {
        grid-column: 4 / 7;
        grid-row: 1 / 3;
        height: 85%;
        width: 100%;
        background: #fff;
        align-self: top;
        z-index: 51;

        display: grid;
        grid-template-rows: repeat(3, 1fr);

        &__paper-line1 {
            grid-row: 1 / 2;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line2 {
            grid-row: 2 / 3;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line3 {
            grid-row: 3 / 4;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
    }
    .graySquare {
        grid-row: 2 / 3;
        grid-column: 4 / 5;
        background: var(--my-background2);
        height: 45%;
        width: 33%;
        justify-self: center;
        margin-right: 13rem;
        z-index: 51;
    }
    .white-bgBar3 {
        grid-column: 5 / 7;
        grid-row: 2 / 3;
        height: 5%;
        width: 85%;
        background: #fff;
        align-self: end;
        justify-self: end;
        margin-bottom: 2rem;
        z-index: 52;
        opacity: 0;
        transition: opacity 1200ms ease-in;
    }
    .white-bgBar4 {
        grid-column: 4 / 7;
        grid-row: 4 / 6;
        height: 90%;
        width: 100%;
        background: #fff;
        align-self: end;
        z-index: 51;

        display: grid;
        grid-template-rows: repeat(3, 1fr);

        &__paper-line1 {
            grid-row: 1 / 2;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line2 {
            grid-row: 2 / 3;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line3 {
            grid-row: 3 / 4;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
    }

    /////  HEADERS /////
    ///////////////////

    .about-me {
        text-transform: uppercase;
        font-size: 7rem;
        font-weight: 400;

        grid-row: 2 / 3;
        grid-column: 1 / 4;
        margin-top: -3rem;
        justify-self: center;
        z-index: 53;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
    }

    .aboutMe-grid {
        grid-row: 2 / 4;
        grid-column: 4 / 7;
        z-index: 53;

        display: grid;
        grid-template-rows: repeat(6, 7rem);
        grid-template-columns: repeat(4, 1fr);
    }

    .adamRiles {
        grid-row: 1 / 2;
        grid-column: 1 / 4;
        text-transform: uppercase;
        font-size: 5rem;
        color: #000;
        font-weight: 400;
        align-self: center;
        margin-left: 5rem;
        z-index: 53;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
    }
    .dev-title {
        grid-row: 2 / 3;
        grid-column: 1 / 3;
        text-transform: capitalize;
        font-weight: 600;
        align-self: center;
        justify-self: start;
        color: #000;
        z-index: 52;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;

        .slash {
            color: #fff;
        }
        .UI {
            color: var(--my-red);
        }
        .UX {
            color: var(--my-blue);
        }
    }
    .steveJobs-quote {
        grid-row: 3 / 4;
        grid-column: 1 / 5;
        font-weight: 600;
        color: #fff;
        z-index: 52;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
    }
    .aboutMe-text {
        grid-row: 4 / 6;
        grid-column: 1 / 5;
        padding-right: 10rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400;
        color: var(--my-text-color);
        align-self: start;
        z-index: 52;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
    }


    .white-gridBars {
        grid-row: 2 / 4;
        grid-column: 1 / 4;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        z-index: 52;

        .white-gridBar {
            height: 1.2rem;
            width: 65%;
            background: linear-gradient(to left, rgba(194, 190, 190, 0.2), #fff);
            align-self: center;
            z-index: 52;
        }
    }

    .myPic {
        grid-row: 2 / 5;
        grid-column: 2 / 4;
        justify-self: center;
        align-self: center;
        margin-bottom: 8rem;

        display: grid;
        grid-template-columns: minmax(10rem, 1fr);
        grid-template-rows:  min-content;
        z-index: 52;

        .myPic-container {
            grid-row: 2 / 4;
            grid-column: 2 / 4;
    
    
            &__image {
                width: 100%;
                transition: all .25s linear;
                -o-transition: all .25s linear;
                -moz-transition: all .25s linear;
                -webkit-transition: all .25s linear;
            }
        }
    }

    .linkedInLink {
        grid-row: 3 / 4;
        grid-column: 6 / 7;
        align-self: center;
        justify-self: end;

        .linkedInLogo {
            transform: rotate(-90deg);
            
            &:hover {
                cursor: pointer;
            }
        }
    }

    .aboutPics-grid {
        grid-row: 4 / 6;
        grid-column: 2 / 6;
        align-self: center;

        display: grid;
        grid-template-columns: repeat(4, minmax(10rem, 1fr));
        grid-template-rows: min-content;
        column-gap: 10%;
        z-index: 53;

        &__container1 {
            grid-column: 1 / 2;
            box-shadow: .7rem .6rem 2.8rem .1rem rgba(0, 0, 0, 0.24);
            &__aboutPic1 {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
        
        &__container2 {
            grid-column: 2 / 3;
            box-shadow: .7rem .6rem 2.8rem .1rem rgba(0, 0, 0, 0.24);
            &__aboutPic2 {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
        
        &__container3 {
            grid-column: 3 / 4;
            box-shadow: .7rem .6rem 2.8rem .1rem rgba(0, 0, 0, 0.24);
            &__aboutPic3 {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
        
        &__container4 {
            grid-column: 4 / 5;
            box-shadow: .7rem .6rem 2.8rem .1rem rgba(0, 0, 0, 0.24);
            &__aboutPic4 {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
            }
        }
    }
}




///// LEFT STAGS /////

.about-stag-left {
    opacity: 0;
    transition: opacity 1200ms ease-in;
}
.about-stag-left.about-animate-left {
    animation: animationLeft 1000ms;
    opacity: 1;
}


.about-stag-left2 {
    opacity: 0;
    transition: opacity 1200ms ease-in 500ms;
}
.about-stag-left2.about-animate-left2 {
    animation: animationLeft 1200ms 500ms;
    opacity: 1;
}


///// UP STAGS /////
.about-stag-up {
    opacity: 0;
    transition: opacity 1200ms ease-in;
}
.about-stag-up.about-animate-up {
    animation: animationUp 1000ms;
    opacity: 1;
}

.white-bgBar3.about-animate-right {
    animation: animationRight 1000ms;
    opacity: 1;
}




.modal-pic {
    width: 100%;
}

.customOverlay {
    background: rgba(0, 0, 0, 0.8);
  }
  .customModal {
    background: #fff;
    max-width: 50%;
    width: 100%;
  }


///// MEDIA QUERIES /////

// // 1648px
// @media screen and (max-width: 1648px){
//     .steveJobs-quote {
//         font-size: 1.7rem;
//     }

//     .aboutMe-text {
//         font-size: 1.7rem;
//     }
// }

// // 1568px
// @media screen and (max-width: 1648px){
//     .steveJobs-quote {
//         font-size: 1.6rem;
//     }

//     .aboutMe-text {
//         font-size: 1.6rem;
//     }

//     .adamRiles {
//         font-size: 4.7rem;
//     }
// }

// 1300px
@media screen and (max-width: 1300px){
    .section2-container .myPic .myPic-container__image {
        margin-left: -1.5rem;
    }

    .section2-container .aboutPics-grid__container1__aboutPic1 {
        width: 110%;
    }
    .section2-container .aboutPics-grid__container2__aboutPic2 {
        width: 110%;
    }
    .section2-container .aboutPics-grid__container3__aboutPic3 {
        width: 110%;
    }
    .section2-container .aboutPics-grid__container4__aboutPic4 {
        width: 110%;
    }

}

// // 1206px
// @media screen and (max-width: 1206px){
//     .steveJobs-quote {
//         font-size: 1.4rem;
//     }
// }

// 1200px
@media screen and (max-width: 1200px){
    .section2-container .myPic .myPic-container__image {
        width: 99%;
    }
    .aboutMe-text{
       margin-left: 3rem;
       grid-row: 3 / 4;
       grid-column: 4 / span 3;
    }
    .steveJobs-quote {
        margin-left: 3rem;
    }
    .adamRiles{
        grid-column: 4 / 7;
    }
    .dev-title{
        margin-left: 3rem;
        grid-row: 2 / 3;
        grid-column: 4 / 7;
    }

    .white-gridBars {
        height: 90%;
    }

    .about-me {
        text-transform: uppercase;
        font-size: 6.5rem;
        font-weight: 400;
    
        grid-row: 2 / 3;
        grid-column: 1 / 4;
        margin-top: -3rem;
        justify-self: center;
        z-index: 53;
    }
    
    .section2-container .aboutPics-grid__container1__aboutPic1 {
        width: 120%;
    }
    .section2-container .aboutPics-grid__container2__aboutPic2 {
        width: 120%;
    }
    .section2-container .aboutPics-grid__container3__aboutPic3 {
        width: 120%;
    }
    .section2-container .aboutPics-grid__container4__aboutPic4 {
        width: 120%;
    }
}

// 1100px
@media screen and (max-width: 1100px){
    .section2-container .myPic .myPic-container__image {
        width: 105%;
        margin-left: -3rem;
    }

    .white-gridBars {
        height: 88%;

        .white-gridBar {
            height: .5rem;
        }
    }

    .about-me {

        font-size: 5.8rem;
    }

   .dev-title {
       margin-bottom: 1rem;
   }

    .section2-container .white-bgBar4 {
        height: 80%;
    }
}

// 1000px
@media screen and (max-width: 1000px){
    .section2-container .myPic{
        grid-column: 1 / 4;
        margin-left: 4rem;
    }
    .section2-container .myPic .myPic-container__image {
        width: 85%;
        margin-left: 3rem;
    }
    .about-me {
        margin-top: .4rem;
        font-size: 5.4rem;
    }
    .white-gridBars {
        height: 75%;
        margin-top: 3rem;
        grid-row: 2 / 4;
        grid-column: 1 / 4;

        .white-gridBar {
            height: .3rem;
        }
    }

    .section2-container {

        .adamRiles {
            font-size: 4rem;
         }
        .dev-title {
            font-size: 1.7rem;
        }
        .steveJobs-quote {
            font-size: 1.7rem;
        }
        .aboutMe-text{
            margin-left: 3rem;
            font-size: 1.7rem;
        }
    }
}

// 900px
@media screen and (max-width: 900px){
    .section2-container .myPic .myPic-container__image {
        width: 90%;
        margin-left: 2rem;
    }
    .about-me {
        margin-top: 3rem;
        font-size: 5rem;
    }
    .white-gridBars {
        height: 75%;
        margin-top: 5rem
    }
    .dev-title {
        margin-bottom: .4rem;
    }
}

// 850px
@media screen and (max-width: 850px){
    .section2-container .myPic .myPic-container__image {
        width: 95%;
        margin-left: -2rem;
    }
    .aboutMe-text {
        margin-left: 0rem;
     }
     .adamRiles {
         grid-column: 4 / 7;
         margin-left: 0rem;
     }
    .dev-title {
        grid-row: 2 / 3;
        grid-column: 4 / 7;
    }
    .section2-container .white-bgBar4 {
        height: 75%;
    }
}

// 800px
@media screen and (max-width: 800px){
    .about-stag-left.about-animate-left {
        grid-row: 2 / 5;
        margin-left: -2rem;
        margin-top: -3rem;

    }
    .section2-container .about-me {
        grid-row: 2 / 3;
        font-size: 5rem;
        margin-top: -2rem;
        align-self: start;
    }

    .section2-container .white-bgBar1 {
        height: 3%;
    }

    .section2-container .white-gridBars {
        height: 55%;
        margin-top: 0rem;
        margin-left: -4rem;
        align-self: start;
        grid-row: 2 / 5;
        grid-column: 1 / 4;

        .white-gridBar {
            height: 1rem;
        }
    }

    .section2-container .white-bgBar2 {
        display: none;
    }

    .section2-container .graySquare {
        display: none;
    }

    .section2-container .white-bgBar4 {
        grid-row: 2 / 4;
        height: 100%;
        width: 75%;
        margin-top: 4rem;
        justify-self: end;
        align-self: center;
    }
    .section2-container .myPic .myPic-container__image {
        width: 95%;
        
    }
    .section2-container .aboutPics-grid {
        grid-row: 1 / 4;
        grid-column: 4 / 7;
        align-self: end;
        padding-right: 4rem;
        padding-bottom: 2rem;

        grid-template-columns: repeat(2, minmax(10rem, 1fr));
        grid-template-rows: repeat(2, 1fr);
        column-gap: 2rem;
    }
    .section2-container .aboutPics-grid__container1 {
        grid-column: 1 / 2;
        width: 82%;
        height: 82%;
    }
    .section2-container .aboutPics-grid__container2 {
        grid-column: 2 / 3;
        width: 82%;
        height: 82%;
    }
    .section2-container .aboutPics-grid__container3 {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        width: 82%;
        height: 82%;
    }
    .section2-container .aboutPics-grid__container4 {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        width: 82%;
        height: 82%;
    }
    .section2-container .aboutMe-grid {
        grid-row: 4 / 6;
        grid-column: 2 / 7;
        z-index: 53;
    }
    .section2-container .dev-title {
        margin-left: 0rem;
        margin-bottom: 0rem;
        align-self: start;
        color: #fff;
    }
    .section2-container .steveJobs-quote {
        grid-row: 2 / 3;
        align-self: end;
        font-size: 1.5rem;
        margin-left: 0rem;
    }
    .section2-container .adamRiles {
        align-self: start;
        font-size: 3rem;
        color: #fff;
        margin-left: 0rem;
        margin-top: 2.5rem;
    }
    .section2-container .aboutMe-text {
        grid-row: 3 / 5;
        grid-column: 1 / 4;
        padding-right: 0rem;
        font-size: 1.5rem;
        align-self: center;
        margin-left: 0rem;
        margin-top: 0rem;
     }
     .section2-container .linkedInLink {
         grid-column: 1 / 2;
         grid-row: 3 / 5;
         justify-self: start;
         margin-left: -2.5rem;
         cursor: pointer;
     }
}
// 753px
@media screen and (max-width: 753px) {
    .section2-container .steveJobs-quote {
        margin-top: 0rem;
        grid-column: 1 / 4;
        align-self: end;
        color: #fff;
        line-height: 1.8rem;
    }


    .section2-container .dev-title {
        margin-top: 0rem;
        grid-column: 1 / 6;
        align-self: start;
        color: #fff;
    }
}
// 730px
@media screen and (max-width: 730px) {
    .section2-container .dev-title {
        margin-bottom: 2rem;
    }

    .steveJobs-quote {
        line-height: 1.5rem;
    }
    .section2-container .aboutMe-text {
        padding-bottom: 0rem;
        align-self: center;
        margin-left: 0rem;
     }

}
// 718px
@media screen and (max-width: 718px) {
    .aboutMe-text {
        align-self: center;
        margin-left: 0rem;
     }
}

// 650px
@media screen and (max-width: 650px) {
    .about-stag-left.about-animate-left {
        grid-row: 2 / 5;
        margin-left: -3rem;
        margin-top: -3rem;

    }

    .section2-container .myPic .myPic-container__image {
        width: 85%;
    }

    .section2-container .about-me {
        grid-row: 2 / 3;
        font-size: 4.6rem;
        margin-top: 0rem;
        align-self: start;
    }

    .section2-container .white-gridBars {
        height: 50%;
        margin-top: 0rem;
        margin-left: -4rem;
        align-self: start;
        grid-row: 2 / 5;
        grid-column: 1 / 4;

        .white-gridBar {
            height: .8rem;
            width: 60%;
        }
    }

    .section2-container .aboutPics-grid {
        padding-bottom: 4rem;

        grid-template-columns: repeat(2, minmax(10rem, 1fr));
        grid-template-rows: repeat(2, 1fr);
        row-gap: 0rem;
        column-gap: 1.5rem;
        margin-left: -3rem;
    }

    .section2-container .adamRiles {
        align-self: start;
        font-size: 3rem;
        color: #fff;
        margin-top: 0rem;
    }

    .section2-container .dev-title {
        grid-row: 1 / 2;
        margin-bottom: 0rem;
        align-self: end;
    }

    .section2-container .steveJobs-quote {
        align-self: center;
    }
    // .dev-title{
    //     margin-left: 0rem;
    //     margin-top: -5rem;
    //     grid-row: 4 / 5;
    //     grid-column: 2 / 6;
    //     align-self: center;
    //     color: #fff;
    // }
    // .adamRiles{
    //     grid-row: 4 / 5;
    //     grid-column: 2 / 5;
    //     align-self: start;
    //     font-size: 3rem;
    //     color: #fff;
    //     margin-left: 0rem;
    //     margin-top: 0rem;
    // }
    // .aboutMe-text{
    //     padding-bottom: 5rem;
    //     align-self: center;
    //     margin-left: 0rem;
    //  }

}
// 610px
@media screen and (max-width: 610px) {
    .section2-container {
        grid-template-columns: [full-start] repeat(6, 1fr) [full-end];
        grid-template-rows: repeat(5, 1fr);
    }

    .about-stag-left.about-animate-left {
        grid-row: 2 / 5;
        margin-left: -2rem;
        margin-top: 0rem;
        align-self: start;

    }

    .section2-container .white-gridBars {
        margin-top: 0rem;
        margin-left: -4rem;
        align-self: start;
        grid-row: 2 / 3;
        grid-column: 1 / 4;

        .white-gridBar {
            height: .8rem;
            width: 50%;
        }
    }

    .section2-container .white-bgBar1 {
        height: 3%;
        margin-top: -5rem;
    }

    .section2-container .white-gridBars {
        height: 44%;
        margin-top: -2rem;
        margin-left: -4rem;
        align-self: center;
        grid-row: 1 / 4;
        grid-column: 1 / 4;
    }

    .section2-container .white-bgBar4 {
        grid-row: 1 / 4;
        height: 60%;
        width: 75%;
        margin-top: 4rem;
        justify-self: end;
        align-self: center;
    }
    .section2-container .myPic .myPic-container__image {
        width: 80%;
        
    }
    .section2-container .aboutPics-grid {
        grid-row: 2 / 4;
        grid-column: 4 / 7;
        align-self: start;
        padding-right: 2rem;
        padding-top: 2rem;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 0rem;
        column-gap: 0rem;
        
    }
    .section2-container .aboutPics-grid__container1 {
        width: 90%;
    }
    .section2-container .aboutPics-grid__container2 {
        width: 90%;
    }
    .section2-container .aboutPics-grid__container3 {
        width: 90%;
    }
    .section2-container .aboutPics-grid__container4 {
        width: 90%;
    }


    .section2-container .aboutPics-grid__container1__aboutPic1 {
        width: 100%;
    }
    .section2-container .aboutPics-grid__container2__aboutPic2 {
        width: 100%;
    }
    .section2-container .aboutPics-grid__container3__aboutPic3 {
        width: 100%;
    }
    .section2-container .aboutPics-grid__container4__aboutPic4 {
        width: 100%;
    }

    .section2-container .about-me {
        margin-top: -5rem;
        font-size: 3.5rem;
    }

    .section2-container .dev-title {
        grid-row: 3 / 4;
        align-self: start;
        margin-top: 0rem;
        font-size: 2rem;
        color: #fff;
    }
    .section2-container .steveJobs-quote {
        margin-top: 0rem;
        grid-row: 4 / 5;
        align-self: start;
        color: #fff;
        line-height: 2.5rem;
    }
    .section2-container .adamRiles {
        grid-row: 2 / 3;
        align-self: center;
        font-size: 3.5rem;
        color: #fff;
    }
    .section2-container .aboutMe-grid {
        grid-row: 3 / 5;

        display: grid;
        grid-template-rows: repeat(8, 6rem);
    }
    .section2-container .aboutMe-text {
        grid-row: 5 / 8;
        grid-column: 1 / 4;
        padding-top: 0rem;
        margin-top: 0rem;
        
     }
     .section2-container .linkedInLink {
         grid-column: 1 / 2;
         grid-row: 3 / 4;
         justify-self: start;
         margin-left: -2.5rem;
         cursor: pointer;
     }
}

// 590px
@media screen and (max-width: 590px) {
    .section2-container .white-bgBar4 {
        grid-row: 1 / 4;
        height: 60%;
        width: 79%;
        margin-top: 4rem;
        justify-self: end;
        align-self: center;
    }
    .section2-container .aboutPics-grid {
        grid-row: 2 / 4;
        grid-column: 4 / 7;
        align-self: start;
        padding-right: 3rem;

        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        row-gap: 0rem;
        column-gap: 0rem;
        
    }
}

// 570px
@media screen and (max-width: 570px) {
    .section2-container .white-bgBar4 {
        height: 60%;
        width: 81%;
    }
    .section2-container .aboutPics-grid {
        padding-right: 7rem;
        
    }
}

// 550px
@media screen and (max-width: 550px) {
    .section2-container .aboutPics-grid {
        padding-right: 10rem;
        column-gap: 1rem;
        
    }
    .section2-container .aboutPics-grid__container1 {
        width: 11rem;
    }
    .section2-container .aboutPics-grid__container2 {
        width: 11rem;
    }
    .section2-container .aboutPics-grid__container3 {
        width: 11rem;
    }
    .section2-container .aboutPics-grid__container4 {
        width: 11rem;
    }
}

// 520px
@media screen and (max-width: 520px) {
    .section2-container .white-bgBar4 {
        height: 55%;
        width: 90%;
    }
    .section2-container .aboutPics-grid {
        padding-top: 3rem;
        margin-left: -6rem;
        
    }
    .section2-container .white-gridBars {
        height: 55%;
        margin-top: -4rem;
        margin-left: 0rem;
        align-self: start;
        grid-row: 2 / 4;
        grid-column: 1 / 3;

        .white-gridBar {
            height: .7rem;
            width: 80%;
        }
    }
    .section2-container .aboutPics-grid__container1__aboutPic1 {
        width: 12rem;
    }
    .section2-container .aboutPics-grid__container2__aboutPic2 {
        width: 12rem;
    }
    .section2-container .aboutPics-grid__container3__aboutPic3 {
        width: 12rem;
    }
    .section2-container .aboutPics-grid__container4__aboutPic4 {
        width: 12rem;
    }

    .section2-container .myPic .myPic-container__image {
        width: 18rem;
    }
    .section2-container .myPic .myPic-container {
        grid-row: 2 / 4;
        grid-column: 1 / 3;
        margin-right: 1rem;
    }
    .section2-container .adamRiles {
        grid-row: 2 / 3;
        margin-top: 2rem;
        font-size: 3.5rem;
        color: #fff;
    }
    .dev-title {
        margin-left: 3rem;
        margin-top: 0rem;
        grid-row: 3 / 4;
        grid-column: 1 / 6;
        align-self: end;
        color: #fff;
    }
    .section2-container .about-me {
        margin-top: -5rem;
        padding-right: 5rem;
        font-size: 3.5rem;
    }

    .aboutMe-text {
        align-self: start;
        margin-left: 3rem;
        margin-top: 0rem;
        padding-right: 3rem;
     }
     .section2-container .linkedInLink {
         grid-column: 1 / 2;
         grid-row: 3 / 4;
         justify-self: start;
         align-self: start;
         margin-top: 3rem;
         margin-left: -4.3rem;
         cursor: pointer;
     }
}

// 500px
@media screen and (max-width: 500px) {
    .section2-container {

         .about-me {
            margin-top: -5rem;
            padding-right: 5rem;
            font-size: 3rem;
        }
    

        .aboutPics-grid {
            grid-column: 3 / 6;
            padding-right: 0rem;
            column-gap: 1rem;
            
        }

        .white-bgBar4 {
            height: 55%;
            width: 90%;
        }

        .aboutPics-grid {
            margin-left: -5rem;
            grid-column: 4 / 5;
        }

         .white-gridBars {
            height: 100%;
            margin-top: -5rem;
            margin-left: -2.5rem;
            align-self: start;
            grid-row: 2 / 3;
            grid-column: 1 / 3;

            .white-gridBar {
                height: .6rem;
                margin-left: 4rem;
            }
        }

        .aboutPics-grid__container1__aboutPic1 {
            width: 11rem;
        }

        .aboutPics-grid__container2__aboutPic2 {
            width: 11rem;
        }

        .aboutPics-grid__container3__aboutPic3 {
            width: 11rem;
        }

        .aboutPics-grid__container4__aboutPic4 {
            width: 11rem;
        }

        .myPic .myPic-container {
            grid-row: 2 / 4;
            grid-column: 1 / 3;

            &__image {
                width: 15rem;
            }
        }

        .linkedInLink {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            justify-self: start;
            align-self: start;
            margin-top: 5rem;
            cursor: pointer;
        }

    }
    

    .section2-container .dev-title {
        // margin-top: 2rem;
        grid-row: 3 / 4;
        align-self: start;
        margin-bottom: 0rem;
        font-size: 2rem;
        color: #fff;
    }
    .section2-container .steveJobs-quote {
        margin-top: 0rem;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        align-self: start;
        color: #fff;
        line-height: 1.8rem;
    }
    .section2-container .adamRiles {
        margin-top: 0rem;
        grid-row: 2 / 4;
        align-self: start;
        font-size: 3.5rem;
        color: #fff;
    }
    .section2-container .aboutMe-grid {
        grid-row: 3 / 5;

        display: grid;
        grid-template-rows: repeat(8, 6rem);
    }
    .section2-container .aboutMe-text {
        grid-row: 6 / 8;
        grid-column: 1 / 3;
        padding-top: 0rem;
        margin-top: 0rem;
        
     }
    
    
}

// 446px
@media screen and (max-width: 446px) {
    .section2-container {

        .aboutPics-grid {
            grid-column: 3 / 6;
            padding-right: 3rem;
            column-gap: 1rem;
            
        }

        .white-bgBar4 {
            height: 55%;
            width: 90%;
        }

        .aboutPics-grid {
            margin-left: -5rem;
            grid-column: 4 / 5;
        }

        .aboutPics-grid__container1__aboutPic1 {
            width: 11rem;
        }

        .aboutPics-grid__container2__aboutPic2 {
            width: 11rem;
        }

        .aboutPics-grid__container3__aboutPic3 {
            width: 11rem;
        }

        .aboutPics-grid__container4__aboutPic4 {
            width: 11rem;
        }

        .myPic .myPic-container__image {
            width: 14rem;
        }

        .myPic .myPic-container {
            grid-row: 2 / 4;
            grid-column: 1 / 3;
            margin-right: 1rem;
        }

        // .linkedInLink {
        //     grid-column: 1 / 2;
        //     grid-row: 3 / 4;
        //     justify-self: start;
        //     align-self: start;
        //     margin-top: 5rem;
        //    //  margin-left: -3rem;
        //     cursor: pointer;
        // }

    }
    

    .aboutMe-text {
        margin-left: 4rem;
        margin-top: 10rem;
        padding-right: 4rem;
    }
}

// 422px
@media screen and (max-width: 422px) {
    .section2-container {
        .white-gridBars {
            display: none;

            .white-gridBar {
                display: none;
            }
        }

        .white-bgBar4 {
            grid-column: 2 / 7;
            margin-top: -4rem;
            height: 55%;
            width: 90%;
        }

        .aboutPics-grid {
            grid-column: 2 / 5;
            padding-right: 0rem;
            padding-top: 0rem;
            column-gap: 0rem;
            
        }

        .aboutPics-grid__container1__aboutPic1 {
            width: 15rem;
        }

        .aboutPics-grid__container2__aboutPic2 {
            width: 15rem;
        }

        .aboutPics-grid__container3__aboutPic3 {
            width: 15rem;
        }

        .aboutPics-grid__container4__aboutPic4 {
            width: 15rem;
        }

        .myPic .myPic-container__image {
            display: none;
        }

        .myPic .myPic-container {
            display: none;
        }

         .about-me {
            color: #000;
        }

        .linkedInLink {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            justify-self: start;
            align-self: start;
            margin-top: 5rem;
            cursor: pointer;
        }

    }

    .section2-container .adamRiles {
        font-size: 3rem;
        grid-row: 2 / 3;
        align-self: end;
        margin-bottom: -2rem;
    }
    .section2-container .dev-title {
        align-self: center;
    }
}

// 382px
@media screen and (max-width: 382px) {
    .white-bgBar4 {
        grid-column: 2 / 7;
        margin-top: -4rem;
        height: 55%;
        width: 90%;
    }

    .section2-container .aboutPics-grid {
        grid-column: 2 / 4;
        padding-right: 0rem;
        padding-top: 0rem;
        column-gap: 1rem;
        
    }
    .aboutPics-grid__container1 {
        align-self: center;
    }

    .aboutPics-grid__container2{
        align-self: center;
    }

    .section2-container .aboutPics-grid__container1__aboutPic1 {
        width: 15rem;
        padding-right: 3rem;
    }

    .section2-container .aboutPics-grid__container2__aboutPic2 {
        width: 12rem;
        height: 8.5rem;
    }

    .section2-container .aboutPics-grid__container3__aboutPic3 {
        width: 15rem;
        padding-right: 3rem;
    }

    .section2-container .aboutPics-grid__container4__aboutPic4 {
        width: 12rem;
        height: 8.5rem;
    }

    .section2-container .about-me {
        color: #000;
        font-size: 3rem;
    }

    .section2-container .adamRiles {
        margin-top: 0rem;
        align-self: start;
        font-size: 3.5rem;
        color: #fff;
    }
    .section2-container .dev-title {
        grid-row: 3 / 4;
        align-self: start;
        margin-bottom: 0rem;
        font-size: 2rem;
        color: #fff;
    }
    .section2-container .steveJobs-quote {
        margin-top: 0rem;
        grid-row: 4 / 5;
        grid-column: 1 / 3;
        align-self: start;
        color: #fff;
        line-height: 1.8rem;
    }
    .section2-container .aboutMe-grid {
        grid-row: 3 / 5;
        margin-left: -5rem;
    }
    .section2-container .aboutMe-text {
        grid-row: 5 / 9;
     }
}

// 368px
@media screen and (max-width: 368px){
    .ux-ui {
        display: block;
    }
    
}

// 328px 
@media screen and (max-width: 328px) {
    .section2-container .about-me {
        font-size: 3.5rem;
    }
    .section2-container .aboutPics-grid {
        column-gap: 0rem;
    }
    .aboutMe-text {
        margin-top: 12rem;
        padding-right: 10rem;
    }
    .section2-container .aboutMe-grid {
        grid-row: 3 / 5;
        margin-left: -8rem;
    }
}




///////////////////////////  DEVICES  //////////////////////////////
//////////////////////////////////////////////////////////////////

// Portrait
// iPhone XR
@media screen and (max-height: 896px) and (max-width: 414px) and (orientation: portrait) {
    .section2-container {

         .aboutMe-grid {
            grid-row: 3 / 5;
            margin-left: -5rem;
        }
    }
}

///////////////////////////////////////////////////
//////////////////////////////////////////////////

// Landscape
// Random laptops
@media screen and (max-height: 766px) and (orientation: landscape){
    
    .section2-container {
        & .myPic .myPic-container__image {
            width: 85%;
        }
        .adamRiles {
            margin-top: 0rem;
            margin-bottom: 3rem;
            font-size: 4.5rem;
            align-self: end;
        }
        .dev-title {
            margin-left: 1rem;
            margin-bottom: 0rem;
            font-size: 1.7rem;
            grid-row: 2 / 3;
            align-self: end;
        }
        .aboutMe-text {
            grid-row: 3 / 5;
            font-size: 1.5rem;
            margin-top: 0rem;
           
        }
    }
}
//////////////////////////////////////////////////
/////////////////////////////////////////////////

// Portrait
// Galaxy S9
@media screen and (max-height: 740px) and (max-width: 360px) {
    .section2-container {
        .adamRiles {
            font-size: 2.5rem;
        }
        .dev-title {
            margin-bottom: 0rem;
            font-size: 1.7rem;
            grid-row: 2 / 3;
            align-self: end;
        }
        .steveJobs-quote {
            grid-row: 3 / 4;
            font-size: 1.2rem;
            align-self: center;
        }
        .aboutMe-text {
            grid-row: 4 / 7;
            font-size: 1.5rem;
            margin-top: 0rem;
           
        }
    }
}

// Portrait
// iPhone 6/7/8 Plus
@media screen and (max-height: 736px) and (max-width: 414px) and (orientation: portrait) {

    .section2-container {
        .adamRiles {
            grid-row: 3 / 4;
            font-size: 2.5rem;
            margin-top: 0rem;
            margin-bottom: 0rem;
            align-self: start;
        }
        .dev-title {
            align-self: end;
            font-size: 1.6rem;
        }
        .steveJobs-quote {
            font-size: 1.3rem;
            align-self: center;
        }
        .aboutMe-text {
            grid-row: 5 / 8;
            font-size: 1.4rem;
            align-self: start;
           
        }
    }
}
//////////////////////////////////////////////////
/////////////////////////////////////////////////
// Landscape
// Random laptops
@media screen and (max-height: 700px) and (orientation: landscape){
    
    .section2-container {
        .adamRiles {
            margin-top: 0rem;
            margin-bottom: 3rem;
            font-size: 4.5rem;
            align-self: end;
        }
        .dev-title {
            margin-left: 1rem;
            margin-bottom: 0rem;
            font-size: 1.7rem;
            grid-row: 2 / 3;
            align-self: end;
        }
        .aboutMe-text {
            grid-row: 3 / 4;
            font-size: 1.5rem;
            margin-top: 0rem;
           
        }
    }
}
//////////////////////////////////////////////////
/////////////////////////////////////////////////

// Portrait
// iPhone 6/7/8
@media screen and (max-height: 667px) and (max-width: 375px) and (orientation: portrait) {
    .section2-container {
        .adamRiles {
            grid-row: 2 / 3;
            align-self: end;
        }
        .dev-title {
            align-self: start; 
        }
        .steveJobs-quote {
            grid-row: 3 / 4;
            align-self: end;
            margin-bottom: -1rem;
        }
        .aboutMe-text {
            grid-row: 4 / 6;
            font-size: 1.4rem;
            margin-top: 2rem;
           
        }
    }
    
}

// Portrait
// LG Optimus L70
@media screen and (max-height: 640px) and (max-width: 384px) and (orientation: portrait) {
    .section2-container {
        .steveJobs-quote {
            grid-row: 4 / 5;
            align-self: center;
            margin-bottom: 0rem;
            font-size: 1.2rem;
        }
        .aboutMe-text {
            font-size: 1.2rem;
        }
    }
}

// Portrait
// Galaxy Note 3
@media screen and (max-height: 640px) and (max-width: 360px) {
    .section2-container {
        .adamRiles {
            margin-top: 0rem;
            margin-bottom: 0rem;
            align-self: end;
            grid-row: 2 / 3;
        }
        .dev-title {
            margin-bottom: -1rem;
            font-size: 2rem;
            grid-row: 3 / 4;
            align-self: start;
        }
        .steveJobs-quote {
            grid-row: 4 / 5;
            align-self: start;
        }
        .aboutMe-text {
            grid-row: 5 / 7;
            font-size: 1.2rem;
            align-self: center;
            margin-top: -2rem;
            margin-bottom: 0rem;
           
        }
    }
}


/////////////////////////////////////////////////////////

// Landscape
// iPad
@media screen and (max-height: 768px) and (max-width: 1024px) and (orientation: landscape){
    
    .section2-container {
        .adamRiles {
            font-size: 4rem;
        }
        .dev-title {
           margin-bottom: .5rem;
        }
        .steveJobs-quote {
            font-size: 1.6rem;
            grid-column: 1 / 4;
        }
        .aboutMe-text {
            font-size: 1.6rem;
        }
    }
}

// Landscape
// For The Nexus 7 / And Others...
@media screen and (max-height: 600px) and (orientation: landscape) {
    .section2-container {
        grid-row: 3 / 5;

        .adamRiles {
            font-size: 4.5rem;
            margin-bottom: 0rem;
        }
        .dev-title {
            margin-bottom: .5rem;
            font-size: 2rem;
         }
         .steveJobs-quote {
            font-size: 1.6rem;
            grid-column: 1 / 4;
            align-self: center;
        }
        .aboutMe-text {
            font-size: 1.6rem;
            grid-row: 4 / 7;
        }
    }
}

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 428px) and (max-width: 926px) {
    
    .section2-container {
        grid-row: 3 / 5;

        .steveJobs-quote {
            font-size: 1.4rem;
        }
    }
}

// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (max-width: 896px) {
    
    .section2-container {
        grid-row: 3 / 5;
    }
}

// Landscape
// iPhone 6/7/8 Plus
@media screen and (max-height: 360px) and (max-width: 800px) and (orientation: landscape) {

    .section2-container {
        grid-row: 3 / 5;

        .adamRiles {
            font-size: 4rem;
            margin-bottom: 0rem;
        }
        .dev-title {
            margin-bottom: .5rem;
            font-size: 2.5rem;
            align-self: center;
         }
         .steveJobs-quote {
            font-size: 1.6rem;
            grid-row: 3 / 4;
            grid-column: 1 / 4;
            align-self: center;
        }
        .aboutMe-text {
            font-size: 1.8rem;
            align-self: start;
            grid-row: 4 / 7;
        }
    }
}

// Landscape
// iPhone 6/7/8 Plus
@media screen and (max-height: 414px) and (max-width: 736px) and (orientation: landscape) {

    .section2-container {
        grid-row: 3 / 5;

        .adamRiles {
            font-size: 4rem;
            margin-bottom: 0rem;
        }
        .dev-title {
            margin-bottom: .5rem;
            font-size: 2.5rem;
            align-self: center;
         }
         .steveJobs-quote {
            font-size: 1.6rem;
            grid-row: 3 / 4;
            grid-column: 1 / 4;
            align-self: center;
        }
        .aboutMe-text {
            font-size: 1.8rem;
            align-self: start;
            grid-row: 4 / 7;
        }
    }
}

// Landscape
// iPhone 6/7/8
@media screen and (max-height: 375px) and (max-width: 667px) and (orientation: landscape) {

    .section2-container {
        grid-row: 3 / 5;

        .adamRiles {
            font-size: 3.8rem;
            margin-bottom: 0rem;
        }
        .dev-title {
            margin-bottom: .5rem;
            font-size: 2.2rem;
            align-self: center;
         }
         .steveJobs-quote {
            font-size: 1.5rem;
            grid-row: 3 / 4;
            grid-column: 1 / 4;
            align-self: center;
        }
        .aboutMe-text {
            font-size: 1.6rem;
            align-self: start;
            grid-row: 4 / 7;
        }
    }
    
}