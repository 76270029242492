.section1-container {
    grid-row: 1 / 2;
    grid-column: full-start / full-end;
    background-color: var(--my-background);
    z-index: 100;

    display: grid;
    grid-template-columns: [full-start] repeat(6, 1fr) [full-end];
    grid-template-rows: repeat(5, 1fr);

    .devPic-container {
        grid-row: 1 / 6;
        grid-column: 4 / 7;
        margin-top: 10rem;
        // margin-left: 8rem;
        justify-self: center;
        align-self: center;
        z-index: 50;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
        

        &__active {
            animation: wimpyDev 5s ease;
        }

        &__wimpy-dev {
            width: 75%;
        }
    }

    .pic-grid {
        grid-row: 2 / 5;
        grid-column: 2 / 4;
        align-self: center;

        display: grid;
        grid-template-columns: repeat(2, minmax(10rem, 1fr));
        grid-template-rows: repeat(2, min-content);
        column-gap: 3%;
        row-gap: 1.5%;


        &__funkyContainer1 {

            grid-column: 1 / 2;
            grid-row: 1 / 2;
            width: 80%;
            justify-self: end;
            align-self: end;
            z-index: 51;
            opacity: 0;
            transition: all .25s linear;
            -o-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -webkit-transition: all .25s linear;
            

            &__active {
                animation: funkyContainer1 1s ease 2s forwards;
            }
    
            &__funkyImg1 {
                max-width: 100%;
                box-shadow: .2rem 1rem 1rem rgba(61, 61, 61, 0.7);
            }
        }
        &__funkyContainer2 {
    
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            width: 94%;
            align-self: end;
            z-index: 51;
            opacity: 0;
            transition: all .25s linear;
            -o-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -webkit-transition: all .25s linear;
            

            &__active {
                animation: funkyContainer2 1.5s ease 2s forwards;
            }
    
            &__funkyImg2 {
                max-width: 100%;
                box-shadow: .2rem 1rem 1rem rgba(61, 61, 61, 0.7);
            }
        }
        &__funkyContainer3 {
    
            grid-column: 2 / 3;
            grid-row: 2 / 3;
            width: 78%;
            justify-self: start;
            align-self: start;
            z-index: 51;
            opacity: 0;
            transition: all .25s linear;
            -o-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -webkit-transition: all .25s linear;
            

            &__active {
                animation: funkyContainer3 2s ease 2s forwards;
            }
    
            &__funkyImg3 {
                max-width: 100%;
                box-shadow: .2rem 1rem 1rem rgba(61, 61, 61, 0.7);
            }
        }
        &__funkyContainer4 {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            width: 70%;
            align-self: start;
            justify-self: end;
            z-index: 51;
            opacity: 0;
            transition: all .25s linear;
            -o-transition: all .25s linear;
            -moz-transition: all .25s linear;
            -webkit-transition: all .25s linear;
            

            &__active {
                animation: funkyContainer4 2s ease 2s forwards;
            }
    
            &__funkyImg4 {
                max-width: 100%;
                box-shadow: .2rem 1rem 1rem rgba(61, 61, 61, 0.7);
            }
        }
    }

    ///// BACKGROUND EFFECTS /////

    // BLACK STYLING BARS

    .bgShine {
        grid-column: full-start / full-end;
        grid-row: 1 / span 5;
        background: radial-gradient(ellipse at left, #fff, var(--my-background));
        height: 100%;
        width: 100%;
    }

    .black-bgBar1 {
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 36%;
        width: 87%;
        background: linear-gradient(to left, rgba(214, 214, 214, .2), #000 70%);
        align-self: center;
        margin-top: 2rem;
        z-index: 51;
        opacity: 0;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;
        

        &__active {
            animation: bg-bar2-slide ease .7s forwards;
        }
        // &__direction {
        //     animation: bg-bar1-slide 1s ease 1.5s forwards;
        // }
    }
    .black-bgBar2 {
        grid-column: 3 / 4;
        grid-row: 1 / 4;
        width: 70%;
        height: 80%;
        background: linear-gradient(to top, rgba(214, 214, 214, .2), rgb(65, 64, 64), #000 80%);
        margin-left: 4rem;
        z-index: 51;
        opacity: 0;
        

        &__active {
            animation: bg-bar1-slide 1.5s ease 1.5s forwards;
        }
    }
    .black-bgBar3 {
        grid-column: 3 / 7;
        grid-row: 4 / 6;
        height: 20rem;
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 70%);
        margin-top: -4rem;
        z-index: 51;
        opacity: 0;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;


        display: grid;
        grid-template-columns:  repeat(10, 10%) ;
        // grid-template-rows: repeat(3, 1fr);
        

        &__active {
            animation: bg-bar3-slide 1s .2s ease  forwards;
        }
    }
    .black-bgBar4 {
        grid-column: 2 / 3;
        grid-row: 3 / 6;
        height: 78%;
        width: 41%;
        background: linear-gradient(to bottom, rgba(214, 214, 214, .2), rgba(65, 64, 64, .8), #000 80%);
        justify-self: center;
        align-self: end;
        margin-left: 3rem;
        z-index: 51;
        opacity: 0;
        

        &__active {
            animation: bg-bar4-slide 2s ease 1s forwards;
        }
    }

    // WHITE STYLING BARS

    .white-bgBar1 {
        grid-column: 1 / 7;
        grid-row: 1 / 2;
        height: 5%;
        width: 100%;
        background: linear-gradient(to left, rgba(116, 116, 116, 0.2), #fff 70%);
        align-self: center;
        margin-top: 7rem;
        z-index: 50;
    }
    .white-bgBar2 {
        grid-column: 4 / 7;
        grid-row: 2 / 3;
        height: 30%;
        width: 100%;
        background: #fff;

        display: grid;

        &__paper-line1 {
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
    }
    .white-bgBar3 {
        grid-column: 1 / 7;
        grid-row: 2 / 3;
        height: 5%;
        width: 100%;
        background: linear-gradient(to left, rgba(116, 116, 116, 0.2), #fff 70%);
        align-self: center;
        z-index: 50;
    }
    .white-bgBar4 {
        grid-column: 4 / 7;
        grid-row: 3 / 6;
        height: 70%;
        width: 95%;
        background: #fff;
        justify-self: end;
        margin-top: -2rem;
        z-index: 49;

        display: grid;
        grid-template-rows: repeat(4, 1fr);

        &__paper-line1 {
            grid-row: 1 / 2;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line2 {
            grid-row: 2 / 3;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line3 {
            grid-row: 3 / 4;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
        &__paper-line4 {
            grid-row: 4 / 5;
            width: 100%;
            height: .2rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
    }
    .white-bgBar5 {
        grid-column: 3 / 7;
        grid-row: 5 / 6;
        height: 15%;
        width: 100%;
        background: #fff;
        align-self: center;
        margin-top: -5rem;

        display: grid;

        &__paper-line1 {
            width: 100%;
            height: .18rem;
            box-shadow: .2rem .2rem .2rem #a9cbeb;
            align-self: center;
        }
    }
    .white-bgBar6 {
        grid-column: 1 / 7;
        grid-row: 5 / 6;
        height: 5%;
        width: 100%;
        background: linear-gradient(to left, rgba(116, 116, 116, 0.2), #fff 70%);
        align-self: center;
        margin-top: 7rem;
        z-index: 50;
    }
    .white-bgBar7 {
        grid-column: 1 / 2;
        grid-row: 1 / 6;
        width: 6%;
        height: 100%;
        background: linear-gradient(to top, rgb(167, 167, 167), #fff 80%);
        justify-self: center;
        z-index: 49;
    }
    .white-bgBar8 {
        grid-column: 1 / 2;
        grid-row: 1 / 6;
        width: 6%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(167, 167, 167), #fff 80%);
        justify-self: end;
        z-index: 49;
    }
    .white-bgBar9 {
        grid-column: 2 / 3;
        grid-row: 1 / 6;
        width: 6%;
        height: 100%;
        background: linear-gradient(to bottom, rgb(167, 167, 167), #fff 80%);
        justify-self: center;
        margin-left: 3rem;
        z-index: 49;
    }


    ///// TITLE HEADER /////
    ///////////////////////
    .header-title {
        grid-column: 4 / 10;
        align-self: center;
        justify-self: center;
        transition: all .25s linear;
        -o-transition: all .25s linear;
        -moz-transition: all .25s linear;
        -webkit-transition: all .25s linear;

        display: grid;
        grid-template-columns: repeat(2, 30rem);
        grid-template-rows: 8rem 10rem;
        opacity: 0;
        
        &__active {
            animation: headerTitle 2s ease forwards;
        }

        &__flex {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
            justify-self: center;
            align-self: center;
            margin-top: -2.8rem;
            

            display: flex;
            align-items: baseline;
        }

        &__curly-brace1 {
            color: var(--my-code-blue);
            font-size: 9rem;
            font-weight: 400;

        }
        &__curly-brace2 {
            color: var(--my-code-blue);
            font-size: 9rem;
            font-weight: 400;
            margin-left: 1rem;
            position: relative;

            &::before {
                content: "|";
                color: #fff;
                font-size: 7rem;
                font-weight: 300;
                font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                opacity: 0;
                position: absolute;
                top: 2.1rem;
                bottom: 0;
                right: 1.9rem;
                animation: blink-line 1s ease .3s infinite forwards;
            }
        }


        &__diary {
            grid-row: 1;
            grid-column: 1 / 4;
            text-transform: uppercase;
            font-family: 'Times New Roman', Times, serif;
            font-size: 7rem;
            justify-self: center;
            align-self: start;
            margin-top: -3.3rem;
            font-weight: 400;
        }
        &__of-a {
            grid-row: 1;
            grid-column: 1 / 4;
            align-self: end;
            font-size: 3rem;
            color: var(--my-orange);
            text-shadow: -.1rem -.1rem 0 #fff, .1rem -.1rem 0 #fff, -.1rem .1rem 0 #fff, .1rem .1rem 0 #fff;
            font-family: Coming Soon;
            justify-self: center;
            word-spacing: 1.5rem;
            margin-bottom: -1.6rem;
        }

        &__typewriter {
            grid-row: 2 / 3;
            grid-column: 1 / -1;
            justify-self: center;
        }
        
        &__web-dev {
            font-size: 8rem;
            color: var(--my-orange);
            text-shadow: -.1rem -.1rem 0 #fff, .1rem -.1rem 0 #fff, -.1rem .1rem 0 #fff, .1rem .1rem 0 #fff;
            margin-left: 2.2rem;
            font-family: Coming Soon;
            text-transform: capitalize;
            position: relative;

            // &::after {
            //     content: "|";
            //     color: #fff;
            //     font-size: 7rem;
            //     font-weight: 400;
            //     font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            //     opacity: 0;
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     animation: blink-line 1s ease .3s infinite forwards;
            // }
        }
        &__line {
            font-size: 8rem;
            font-weight: 400;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            display: none;
        }
        // &__cursor {
        //     color: #fff;
        //     font-size: 7rem;
        //     font-weight: 400;
        //     opacity: 0;
        //     animation: blink-line 1s ease .3s infinite forwards;
        // }
    }

///// KEY FRAMES /////


    // SECTION 1 ANIMATION
    @keyframes bg-bar1-slide {
        0% {transform: translateY(-100rem);}

        100% {transform: translate(0rem); opacity: 1;}
    }
    @keyframes bg-bar2-slide {
        0% {transform: translateX(-100rem);}

        100% {transform: translate(0rem); opacity: 1;}
    }
    @keyframes bg-bar3-slide {
        0% {transform: translateX(150rem);}

        100% {transform: translate(0rem); opacity: 1;}
    }
    @keyframes bg-bar4-slide {
        0% {transform: translateY(100rem);}

        100% {transform: translate(0rem); opacity: 1;}
    }


    @keyframes headerTitle {
        0% {transform: translateX(100rem);}
        50% {opacity: .1;}
        100% {transform: translate(0rem); opacity: 1;}
    }
    @keyframes blink-line {
        0% {opacity: 0;}

        40% {opacity: 0;}

        100% {opacity: 1;}
    }

    @keyframes wimpyDev {
        0%{opacity: 0;}
        100%{opacity: 1;}
    }


    @keyframes funkyContainer1 {
        0% {transform: translateX(-100rem);}
        50%{opacity: .5;}
        100%{transform: translate(0rem); opacity: 1;}
    }
    @keyframes funkyContainer2 {
        0% {transform: translateY(-100rem);}
        50%{opacity: .5;}
        100%{transform: translate(0rem); opacity: 1;}
    }
    @keyframes funkyContainer3 {
        0% {transform: translateX(100rem);}
        40%{opacity: .2; z-index: 100;}
        50%{opacity: .5;}
        // 70%{opacity: .8;}
        100%{transform: translate(0rem); opacity: 1; z-index: 101;}
    }
    @keyframes funkyContainer4 {
        0% {transform: translateY(100rem);}
        50%{opacity: .5;}
        100%{transform: translate(0rem); opacity: 1;}
    }
      
    // TYPEWRITER ANIMATION
      @-webkit-keyframes blink{
        0%{opacity: 0;}
        100%{opacity: 1;}
      }
      
      @-moz-keyframes blink{
        0%{opacity: 0;}
        100%{opacity: 1;}
      }
      
      @keyframes blink{
        0%{opacity: 0;}
        100%{opacity: 1;}
      }
}


///// MEDIA QUERIES /////
////////////////////////


// 1645px
@media screen and (max-width: 1645px){
    // FUCKY CONTAINERS //
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 88%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 102%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 86%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 78%;
    }


    .black-bgBar1.black-bgBar1__active {
        height: 35%;
        width: 87%;
    }




    .devPic-container.devPic-container__active {
        margin-right: -8rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 74%;
    }
    .header-title.header-title__active {
        margin-left: 5rem;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 8.8rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 8.8rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 6.8rem;
    }
    .header-title.header-title__active .header-title__of-a {
        font-size: 2.8rem;
        padding-top: .1rem;
    }
    
    .header-title.header-title__active .header-title__web-dev {
        font-size: 7.8rem;
        padding-top: .1rem;
    }
    
    
}

// 1600px
@media screen and (max-width: 1600px){
    // FUCKY CONTAINERS //
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 93%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 110%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 94%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 86%;
    }

    .black-bgBar1.black-bgBar1__active {
        height: 34%;
        width: 87%;
    }



    .devPic-container.devPic-container__active {
        margin-right: -10rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 73%;
        
    }

    .section1-container .header-title__curly-brace2 {

        &::before {
            content: "|";
            color: #fff;
            font-size: 7rem;
            font-weight: 300;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            opacity: 0;
            position: absolute;
            top: 2.2rem;
            bottom: 0;
            right: 1.6rem;
            animation: blink-line 1s ease .3s infinite forwards;
        }
    }

}

// 1500px
@media screen and (max-width: 1500px){
    // FUCKY CONTAINERS //
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 98%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 118%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 102%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 94%;
    }

    
    .black-bgBar1.black-bgBar1__active {
        height: 32%;
        width: 87%;
    }



    .devPic-container.devPic-container__active {
        margin-right: -15rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 72%;
        
    }

    .header-title.header-title__active {
        margin-top: 1.5rem;
    }
    
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 8.4rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 8.4rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 6.4rem;
    }
    // .header-title.header-title__active .header-title__of {
    //     font-size: 2.4rem;
    //     padding-top: .2rem;
    // }
    // .header-title.header-title__active .header-title__a {
    //     font-size: 2.4rem;
    //     padding-top: .2rem;
    // }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 7.4rem;
        padding-top: .2rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 6.8rem;
            top: 2.2rem;
            right: 1.6rem;
        }
    }

}

// 1407px
@media screen and (max-width: 1407px){
    // FUCKY CONTAINERS //
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 103%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 126%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 110%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 102%;
    }


}

// 1300px
@media screen and (max-width: 1300px){
    // FUCKY CONTAINERS //
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 108%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 134%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 118%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 110%;
    }

    
    .black-bgBar1.black-bgBar1__active {
        height: 30%;
        width: 87%;
    }



    .devPic-container.devPic-container__active {
        margin-right: -20rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 69%;
        
    }

}

// 1200px
@media screen and (max-width: 1200px){
    // FUCKY CONTAINERS //
    .section1-container .pic-grid {
        margin-left: -8rem
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 93%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 110%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 94%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 86%;
    }

    // BLACK BG BARS //
    .black-bgBar1.black-bgBar1__active {
        grid-column: 1 / 3;
        grid-row: 2 / 4;
        height: 30%;
        width: 65%;
        align-self: center;
        margin-top: 2rem;
    }
    .black-bgBar2.black-bgBar2__active {
        grid-column: 3 / 4;
        grid-row: 1 / 4;
        width: 70%;
        justify-self: start;
        margin-left: 0rem;
    }
    .black-bgBar4.black-bgBar4__active {
        justify-self: start;
        height: 74%;
        width: 50%;
        margin-left: 0rem;
    }


    .devPic-container.devPic-container__active {
        margin-right: -20rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 67%;
        
    }
    
}


// 1100px
@media screen and (max-width: 1100px){
    // FUCKY CONTAINERS //
    
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 93%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 110%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 94%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 86%;
    }

    // BLACK BG BARS //
    .black-bgBar1.black-bgBar1__active {
        height: 30%;
        width: 50%;
    }
    .black-bgBar2.black-bgBar2__active {
        height: 80%;
        width: 73%;
    }
    



    .devPic-container.devPic-container__active {
        margin-right: -20rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 67%;
        
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 7.6rem;
        margin-bottom: 2.3rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 7.6rem;
        margin-bottom: 2.3rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 5.6rem;
        margin-top: -1.5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 6.6rem;
        padding-top: .8rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 6rem;
            top: 1.5rem;
            right: 1.6rem;
        }
    }

}


/////////////////  DEVICE MQ  ////////////////////
/////////////////////////////////////////////////
// Portrait
// iPad Pro
@media screen and (max-height: 1366px) and (max-width: 1024px) and (orientation: portrait){
    
    .black-bgBar3.black-bgBar3__active {
        height: 19rem;
    }
    .black-bgBar1.black-bgBar1__active {
        height: 40%;
        grid-row: 3 / 4;
        align-self: start;
        margin-top: 0rem;
    }
}
// Landscape
// Random laptops
@media screen and (max-height: 766px) and (orientation: landscape){
    
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 65%;
    }
}
@media screen and (max-height: 724px) and (orientation: landscape){
    
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 60%;
    }
}
@media screen and (max-height: 680px) and (orientation: landscape){
    
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 55%;
    }
}
//////////////////////////////////////////////////
/////////////////////////////////////////////////



// 1000px
@media screen and (max-width: 1000px) {
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 88%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 102%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 86%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 78%;
    }

    .section1-container .pic-grid {
        margin-left: -15rem
    }
        // BLACK BG BARS
        .black-bgBar1.black-bgBar1__active {
            grid-column: 1 / 2;
            grid-row: 1 / 4;
            background: linear-gradient(to top, rgba(214, 214, 214, .2), rgb(65, 64, 64), #000 80%);
            height: 75%;
            width: 50%;
            align-self: start;
            justify-self: end;
        }
        .section1-container .black-bgBar1__active {
            animation: bg-bar1-slide ease .8s forwards;
        }

        .header-title.header-title__active {
            grid-column: 4 / 10;
            align-self: center;
            justify-self: center;
            margin-left: 1rem;
        }
}


// 917px
@media screen and (max-width: 917px) {

    .black-bgBar3.black-bgBar3__active {
        height: 19rem;
    }
    .header-title.header-title__active {
        margin-top: 2rem;
        margin-left: -2rem;
    }
}

// 901px
@media screen and (max-width: 901px) {

    .section1-container .pic-grid {
        margin-left: -15rem
    }
    .header-title.header-title__active {
        margin-top: 2rem;
        margin-left: -4rem;    
    }
}

// 800px
@media screen and (max-width: 800px) {

    .black-bgBar1.black-bgBar1__active {
        height: 50%;
        width: 50%;
    }
    .black-bgBar2.black-bgBar2__active {
        height: 53%;
        width: 73%;
    }
    .section1-container .black-bgBar3__active {
        grid-row: 4 / 6;
        grid-column: 1 / 7;
        align-self: center;
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 35%);
        width: 100%;
        z-index: 52;

    }
    .section1-container .black-bgBar4__active {
        height: 90%;

    }

    .header-title.header-title {
        grid-column: 2 / 10;
        margin-left: 0rem;
    }

    .devPic-container.devPic-container__active {
        margin-right: -8rem;
    }
    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 0%;
    }
    .header-title.header-title__active {
        margin-left: 5rem;
        margin-top: 1rem;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 8.8rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 8.8rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 6.8rem;
        margin-top: -3rem;
    }
    .header-title.header-title__active .header-title__of-a {
        font-size: 2.8rem;
        padding-top: .1rem;
    }
    
    .header-title.header-title__active .header-title__web-dev {
        font-size: 7.8rem;
        padding-top: .1rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 7.2rem;
            top: 2rem;
        }
    }

    .section1-container .pic-grid {
        grid-column: 2 / 5;
        align-self: start;
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 76%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 90%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 74%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 66%;
    }
}

// 730px
@media screen and (max-width: 730px) {

    .section1-container .pic-grid {
        grid-column: 2 / 5;
        align-self: start;
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 80%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 94%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 78%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 70%;
    }
}

// 700px
@media screen and (max-width: 700px) {

    .section1-container .pic-grid {
        grid-column: 2 / 5;
        align-self: start;
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 82%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 96%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 80%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 72%;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 35%);
    }
}

// 630px
@media screen and (max-width: 630px) {

    .section1-container .pic-grid {
        grid-column: 2 / 5;
        align-self: start;
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 84%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 98%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 82%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 74%;
    }


    .devPic-container.devPic-container__active .devPic-container__wimpy-dev {
        width: 0%;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 7rem;
        padding-top: .9rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 7rem;
        padding-top: .9rem;

        &::before {

            font-size: 6rem;
            top: 2rem;
            right: 1.6rem;
        }
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 5rem;
        margin-top: -1rem;
    }
    .header-title.header-title__active .header-title__of-a {
        font-size: 2.5rem;
        padding-top: .1rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 6rem;
        padding-top: .8rem;
    }
}

// 615px
@media screen and (max-width: 615px) {

    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 33%);
    }
}

// 550px
@media screen and (max-width: 550px) {

    .header-title.header-title__active {
        margin-right: 5rem;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 27%);
    }
}

// 540px
@media screen and (max-width: 540px) {

    .header-title.header-title__active {
        margin-right: 7rem;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 25%);
    }
}

// 530px
@media screen and (max-width: 530px) {

    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 23%);
    }
}

// 500px
@media screen and (max-width: 500px) {

    .header-title.header-title__active {
        margin-right: 10rem;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(20, 20, 20, 0.2), #000 20%);
    }
}

// 483px
@media screen and (max-width: 483px) {

    .header-title.header-title__active {
        margin-right: 15rem;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(20, 20, 20, 0.2), #000 20%);
    }
}

// 466px
@media screen and (max-width: 466px) {

    .header-title.header-title__active {
        margin-right: 17rem;
    }
    .black-bgBar3.black-bgBar3__active {
        background: linear-gradient(to right, rgba(20, 20, 20, 0.2), #000 18%);
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 80%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 94%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 78%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 70%;
    }
}

// // 432px
// @media screen and (max-width: 432px) {

//     .header-title.header-title__active {
//         margin-right: 17rem;
//     }

//     .header-title.header-title__active .header-title__curly-brace1 {
//         font-size: 7rem;
//         padding-top: .9rem;
//     }
//     .header-title.header-title__active .header-title__curly-brace2 {
//         font-size: 7rem;
//         padding-top: .9rem;
//     }
//     .header-title.header-title__active .header-title__diary {
//         font-size: 4.5rem;
//     }
//     .header-title.header-title__active .header-title__of {
//         font-size: 1.5rem;
//         padding-top: 2rem;
//         padding-bottom: .5rem;
//     }
//     .header-title.header-title__active .header-title__a {
//         font-size: 1.5rem;
//         padding-top: 2rem;
//         padding-bottom: .5rem;
//     }
//     .header-title.header-title__active .header-title__web-dev {
//         font-size: 5.5rem;
//     }
//     .header-title.header-title__active .header-title__line {
//         font-size: 6rem;
//         padding-top: .9rem;
//     }
// }

// 400px
@media screen and (max-width: 400px) {
    .section1-container .pic-grid {
        margin-right: 3rem;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 6.5rem;
        padding-top: .9rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 6.5rem;
        padding-top: .9rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 4.5rem;
    }
    .header-title.header-title__active .header-title__of {
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: .5rem;
    }
    .header-title.header-title__active .header-title__a {
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: .5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 5.5rem;
    }
    .header-title.header-title__active .header-title__line {
        font-size: 5.5rem;
        padding-top: .9rem;
    }
    .black-bgBar4.black-bgBar4__active {
        margin-left: -2rem;
    }
}

// 375px
@media screen and (max-width: 375px) {
    .section1-container .pic-grid {
        margin-right: 5rem;
    }
}

// 362px
@media screen and (max-width: 362px) {
    .section1-container .pic-grid {
        margin-right: 5rem;
    }
    .header-title.header-title__active {
        margin-right: 25rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 4.5rem;
    }
    .header-title.header-title__active .header-title__of {
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: .5rem;
    }
    
    .header-title.header-title__active .header-title__web-dev {
        font-size: 5.5rem;
    }
}

// 354px
@media screen and (max-width: 354px) {
    .section1-container .pic-grid {
        margin-right: 8rem;
    }
    .header-title.header-title__active {
        margin-right: 25rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 4rem;
    }
    .header-title.header-title__active .header-title__of {
        font-size: 1.5rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
    }
    
    .header-title.header-title__active .header-title__web-dev {
        font-size: 5rem;
    }
    
    .black-bgBar4.black-bgBar4__active {
        margin-left: -3rem;
    }
    .section1-container .white-bgBar4 {
        width: 110%;
    }
}

// 330px
@media screen and (max-width: 330px) {
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 76%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 90%;
    }
    
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 66%;
    }
    .black-bgBar4.black-bgBar4__active {
        margin-left: -4rem;
    }
    .section1-container .white-bgBar4 {
        width: 120%;
    }
}

///////////////////////////////////////////////////
//////////////////  DEVICES  /////////////////////
/////////////////////////////////////////////////


// Portrait
// iPhone 12 Pro Max
@media screen and (max-height: 926px) and (max-width: 428px) and (orientation: portrait) {
    
    .section1-container .pic-grid {
        justify-self: start;
        grid-column: 1 / 5;
        column-gap: 2.5%;
        row-gap: 1%;
    }
    
    .black-bgBar1.black-bgBar1__active {
        height: 50%;
        width: 45%;
    }
    .black-bgBar2.black-bgBar2__active {
        grid-column: 2 / 3;
        justify-self: end;
        height: 53%;
        width: 60%;
    }
    .black-bgBar3.black-bgBar3__active {
        grid-row: 4 / 5;
        background: #000;
        margin-top: 0rem;
        height: 15rem;
        width: 100%;
        align-self: start;
    }
    .section1-container .black-bgBar4__active {
        grid-column: 1 / 2;
        height: 100%;
        justify-self: end;
        background: linear-gradient(to bottom, rgba(214, 214, 214, .2), rgba(65, 64, 64, .8), #000 30%);

    }

    .section1-container .white-bgBar2 {
        grid-column: 3 / 7;
    }
    .section1-container .white-bgBar4 {
        grid-column: 3 / 7;
    }
    .section1-container .white-bgBar5 {
        grid-column: 2 / 7;
    }

    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 45%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 53%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 45%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 40%;
    }

    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-left: 0rem;
        margin-right: 17rem;
    }

    .header-title.header-title__active .header-title__curly-brace1 {
        grid-row: 2;
        font-size: 5.8rem;
        padding-top: 0rem;
        padding-bottom: 1.8rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 5.8rem;
        padding-top: 0rem;
        padding-bottom: 1.8rem;
        margin-bottom: 0rem;
        margin-top: 0rem;
        margin-left: 1.5rem;

        &::before {

            font-size: 5rem;
            top: 1rem;
            right: 2rem;
        }
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 3.5rem;
        margin-top: 1.5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 4.5rem;
    }
    .section1-container .header-title__typewriter {
        align-self: end;
    }

} 


// Portrait
// Galaxy A51/A71 / One Plus Nord N100/200/9 Pro
@media screen and (max-height: 919px) and (max-width: 412px) and (orientation: portrait) {

    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 19.6rem;
    }

} 


// Portrait
// iPhone XR / XS Max / 11
@media screen and (max-height: 896px) and (max-width: 414px) and (orientation: portrait) {
    
    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 19.8rem;
    }

} 


// Portrait
// iPhone X/XS 
@media screen and (max-height: 812px) and (max-width: 375px) and (orientation: portrait) {
    
    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 22.8rem;
    }
}



// Portrait
// Galaxy S9
@media screen and (max-height: 740px) and (max-width: 360px) {

    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 24rem;
    }
    
}

// Portrait
// iPhone 6/7/8
@media screen and (max-height: 667px) and (max-width: 375px) and (orientation: portrait) {

    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 23rem;
    }
}


// Portrait
// Galaxy Note 3
@media screen and (max-height: 640px) and (max-width: 360px) {

    .header-title.header-title__active {
        margin-top: -1.5rem;
        margin-right: 24rem;
    }

}


///////////////////////////////////////////////////////////////////////////

// Landscape

// Landscape
// For The Nexus 7 / And Others...
@media screen and (max-height: 600px) and (orientation: landscape) {
    .section1-container {
        grid-row: 1 / 3;
    }
}

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 428px) and (max-width: 926px) and (orientation: landscape){
    
    .section1-container {
        grid-row: 1 / 3;
    }
    .section1-container .pic-grid {
        justify-self: start;
        grid-column: 1 / 5;
        column-gap: 2.5%;
        row-gap: 1%;
    }
    
    .black-bgBar1.black-bgBar1__active {
        height: 50%;
        width: 45%;
    }
    .black-bgBar2.black-bgBar2__active {
        grid-column: 2 / 4;
        justify-self: center;
        height: 53%;
        width: 46%;
    }
    .black-bgBar3.black-bgBar3__active {
        grid-column: 3 / 7;
        grid-row: 3 / 5;
        background: linear-gradient(to right, rgba(214, 214, 214, .2), #000 75%);
        margin-top: 0rem;
        width: 100%;
        align-self: start;
    }
    .section1-container .black-bgBar4__active {
        grid-column: 1 / 2;
        justify-self: end;
        height: 90%;
        width: 48%

    }

    .section1-container .white-bgBar2 {
        grid-column: 3 / 7;
    }
    .section1-container .white-bgBar4 {
        grid-column: 3 / 7;
        width: 83%;
    }
    .section1-container .white-bgBar5 {
        grid-column: 2 / 7;
    }
    
    .pic-grid__funkyContainer1.pic-grid__funkyContainer1__active {
        width: 42%;
    }
    .pic-grid__funkyContainer2.pic-grid__funkyContainer2__active {
        width: 50%;
    }
    .pic-grid__funkyContainer3.pic-grid__funkyContainer3__active {
        width: 42%;
    }
    .pic-grid__funkyContainer4.pic-grid__funkyContainer4__active {
        width: 37%;
    }

    .header-title.header-title__active {
        grid-row: 3 / 5;
        grid-column: 4 / 6;
        margin-right: 4.5rem;
        height: 15rem;
        margin-top: -1rem;
        margin-left: 28rem;
        align-self: start;
        justify-self: center;
    }
}

// Landscape
// iPhone 6/7/8 Plus
@media screen and (max-height: 414px) and (max-width: 736px) and (orientation: landscape) {
    
    .header-title.header-title__active {
        grid-row: 3 / 5;
        grid-column: 4 / 6;
        margin-right: 4.5rem;
        height: 15rem;
        margin-top: -1rem;
        margin-left: 17rem;
        align-self: start;
        justify-self: center;
    }

    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 7.6rem;
        margin-bottom: 2.3rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 7.6rem;
        margin-bottom: 2.3rem;
    }
    .header-title.header-title__active .header-title__diary {
        font-size: 5.6rem;
        margin-top: -1.5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 6.6rem;
        padding-top: .8rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 6rem;
            top: 1.5rem;
            right: 2rem;
        }
    }
}

// Landscape
// iPhone X/XS 
@media screen and (max-height: 375px) and (max-width: 812px) and (orientation: landscape) {
    
    .header-title.header-title__active {
        grid-row: 3 / 5;
        grid-column: 4 / 6;
        margin-right: 4.5rem;
        height: 15rem;
        margin-top: -1rem;
        margin-left: 20rem;
        align-self: start;
        justify-self: center;
    }
}

// Landscape
// iPhone 6/7/8
@media screen and (max-height: 375px) and (max-width: 667px) and (orientation: landscape) {

    .header-title.header-title__active {
        grid-row: 3 / 5;
        grid-column: 4 / 6;
        margin-right: 4.5rem;
        height: 13.5rem;
        margin-top: -2rem;
        margin-left: 20rem;
        align-self: start;
        justify-self: center;
    }

    .header-title.header-title__active .header-title__diary {
        font-size: 5rem;
    }
    .header-title.header-title__active .header-title__of-a {
        font-size: 2.5rem;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 6.5rem;
        padding-top: 1.5rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 6.5rem;
        padding-top: 1.5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 6rem;
        padding-top: 1.5rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 5.5rem;
            top: 2.5rem;
            right: 1.6rem;
        }
    }
}


// Landscape
@media screen and (max-height: 360px) and (orientation: landscape) {
    .section1-container {
        grid-row: 1 / 3;
    }
    .header-title.header-title__active {
        grid-row: 3 / 5;
        grid-column: 4 / 6;
        margin-right: 4.5rem;
        height: 13.5rem;
        margin-top: -2rem;
        margin-left: 20rem;
        align-self: start;
        justify-self: center;
    }

    .header-title.header-title__active .header-title__diary {
        font-size: 5rem;
    }
    .header-title.header-title__active .header-title__of-a {
        font-size: 2.5rem;
    }
    .header-title.header-title__active .header-title__curly-brace1 {
        font-size: 6.5rem;
        padding-top: 1.5rem;
    }
    .header-title.header-title__active .header-title__curly-brace2 {
        font-size: 6.5rem;
        padding-top: 1.5rem;
    }
    .header-title.header-title__active .header-title__web-dev {
        font-size: 6rem;
        padding-top: 1.5rem;
    }
    .section1-container .header-title__curly-brace2 {

        &::before {

            font-size: 5.5rem;
            top: 2.5rem;
            right: 1.6rem;
        }
    }

} 

