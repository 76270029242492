*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

// dsfjnfjcjmnkjjvge

:root {
  --my-background: #808080;
  --my-background2: #c0c0c0;
  --my-green: #4ecf4e;
  --my-text-color: #5a753c;
  --my-yellow: #e7fa3b;
  --my-orange: #ff5924;
  --my-red: #ff0000;
  --my-blue: #1d37cc;
  --my-code-blue: #24b0dc;

}

html {
    box-sizing: border-box;
    font-size: 62.5%;
    
}

body {
    background: white;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 1.8rem;
    color: white;
    font-weight: 300;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.app-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
    overflow: hidden;
  
    position: relative;
}

.home-container {
    display: grid;
    grid-template-rows: 100vh  100vh min-content 100vh 15rem;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
    overflow: hidden;
  
    position: relative;
    grid-column: full-start 1 / -1;
    grid-row: 1 /2;
}

/// OPEN SITE OVERLAY
.openSiteOverlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(24, 23, 23, 0.8);
    z-index: 500;
    animation: siteOverlayFade .5s;
  }
  /// CLOSE SITE OVERLAY
.closeSiteOverlay {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba( #000, .8);
    z-index: 500;
    animation: closeSiteOverlayFade .7s;
  }
  
  
  
  /// NAV SLIDE IN/OUT ANIMATIONS
    
  .slide-open {
    animation: slide-open .7s ease forwards;
  }
  .slide-away {
    animation: slide-away .7s ease forwards;
  }
  


///// KEYFAMES //////////////////
////////////////////////////////

// NAV SLIDES OPEN
@keyframes slide-open {
    0% {
        top: -60rem;
        opacity: 0;
    }
  
    100% {
        top: 0;
        opacity: 1;
    }
  
  }
// NAV SLIDES AWAY  
  @keyframes slide-away {
    0% {
        top: 0;
        opacity: 1;
    }
  
    100% {
        top: -70rem;
        opacity: 0;
    }
  }

// CLOSES OVERLAY FADE
@keyframes closeSiteOverlayFade {
    0% {opacity: 1;}
    
    100% {opacity: 0;}
    }
// OVERLAY FADE IN
@keyframes openSiteOverlayFade {
    0% {opacity: 0;}
    
    100% {opacity: 1;}
    }




@keyframes animationUp {
    0% {
        transform: translateY(50rem);
    }

    100% {
        transform: translate(0rem);
    }
}

@keyframes animationRight {
    0% {
        transform: translateX(50rem);
    }

    100% {
        transform: translate(0rem);
    }
}

@keyframes animationLeft {
    0% {
        transform: translateX(-50rem);
    }

    100% {
        transform: translate(0rem);
    }
}


////////////////////////////////////////////////////////////
//////////////////////// DEVICES //////////////////////////
//////////////////////////////////////////////////////////

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 600px) and (max-width: 960px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}

// Landscape
// iPhone 12 Pro Max
@media screen and (max-height: 428px) and (max-width: 926px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
// Landscape
// Galaxy A51
@media screen and (max-height: 412px) and (max-width: 919px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
// Landscape
// iPhone XR
@media screen and (max-height: 414px) and (max-width: 896px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (max-width: 846px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
// Landscape
// Galaxy S9
@media screen and (max-height: 360px) and (max-width: 740px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
// Landscape
// Galaxy Note 3
@media screen and (max-height: 360px) and (max-width: 640px) {
    .layout-container {
        grid-template-rows: 100vh  100vh 100vh  100vh min-content 100vh 100vh 15rem;
    }
}
