////// UNIVERSAL /////
.code-text {
    font-family: Consolas;
    text-decoration: none;

}
.c1 {
    color: #fff;
}
.c2 {
    color: var(--my-red);
}
.c3 {
    color: var(--my-green);
}
.c4 {
    color: var(--my-yellow);
}
.aTag {
    text-decoration: none;
    color: #fff;
}

.linkedInLogoSm {
    width: 8rem;
}

.gitHubLogo {
    width: 7rem;
}

.hamburger-menu {
    grid-column: 13 / 14;
    width: 5rem;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    transition: all .25s linear;
    -o-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -webkit-transition: all .25s linear;
}
.closeX {
    grid-column: 13 / 14;
    width: 5rem;
    align-self: center;
    justify-self: center;
    cursor: pointer;
    transition: all .25s linear;
    -o-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -webkit-transition: all .25s linear;
}


////////////////////////
.navbar {
    grid-column: full-start / full-end;

    display: grid;
    grid-template-columns: repeat(14, [body-start] 7.14% [body-end]);
    height: 7rem;
    background-color: #000;
    position: fixed;
    width: 100%;
    z-index: 1000;
    transition: all .25s linear;
    -o-transition: all .25s linear;
    -moz-transition: all .25s linear;
    -webkit-transition: all .25s linear;

    &__link1 {
        grid-column: 1 / span 4;
        justify-self: center;
        align-self: center;
        cursor: pointer;
    }
    &__link2 {
        grid-column: 8;
        justify-self: start;
        align-self: center;
        margin-left: 2rem;
        cursor: pointer;
    }
    &__link3 {
        grid-column: 9;
        justify-self: end;
        align-self: center;
        cursor: pointer;
    }
    &__link4 {
        grid-column: 10;
        grid-row: 1 / 2;
        align-self: center;
        justify-self: center;
        margin-right: -10rem;
        cursor: pointer;
    }
    &__link5 {
        grid-column: 11;
        grid-row: 1 / 2;
        justify-self: end;
        align-self: center;
        padding-top: .5rem;
        margin-right: -8rem;
        cursor: pointer;
    }
    &__link6 {
        grid-column: 12 / 13;
        margin-right: -3rem;
        justify-self: end;
        align-self: center;
        fill: #fff;
        cursor: pointer;
    }
    &__link7 {
        grid-column: 13;
        justify-self: center;
        align-self: center;
        // margin-left: -5rem;
        margin-right: -8rem;
    }
}


///// MEDIA QUERIES /////

// 1645px
@media screen and (max-width: 1645px){

    .gitHubLogo {
        width: 7rem;
        padding-top: .7rem;
        margin-left: -1rem;
    }

    .linkedInLogoSm {
        width: 8rem;
    }
    .navbar__link2 {
        margin-left: 0rem;
    }

    .navbar__link5 {
        grid-column: 11 / 12;
        justify-self: center;
        margin-left: 6rem;
    }

    .navbar__link7 {
        grid-column: 13 / 14;
        justify-self: center;
    }
}

// 1500px
@media screen and (max-width: 1500px){

    .gitHubLogo {
        width: 6.7rem;
        padding-top: .7rem;
        margin-left: -3rem;
    }

    .linkedInLogoSm {
        width: 7rem;
    }

    .code-text {
        font-size: 1.7rem;
    }
    
    .navbar {
        height: 6.5rem;

        &__link5 {
            // margin-right: -1rem;
        }
        &__link6 {
            
        }
        &__link7 {
            
        }
    }
}

// 1400px
@media screen and (max-width: 1400px){

    .gitHubLogo {
        width: 6.7rem;
        padding-top: .9rem;
    }

    .code-text {
        font-size: 1.5rem;
    
    }

    .navbar {
        height: 6.4rem;

        &__link5 {
            margin-bottom: -.5rem;
        }
    }
}

// 1300px
@media screen and (max-width: 1300px){

    .linkedInLogoSm {
        width: 7rem;
    }

    .navbar {
    
        &__link6 {
            grid-column: 12;
            margin-right: -4rem;
        }
    }
}

// 1200px
@media screen and (max-width: 1200px){

    .navbar {
        // grid-template-columns: [full-start] repeat(11, [body-start] minmax(7.5rem, 1fr) [body-end]) 1fr [full-end];
        height: 6.3rem;
    
    }
}

// 1100px
@media screen and (max-width: 1100px){

    .gitHubLogo {
        width: 5.5rem;
        padding-top: .9rem;
        margin-left: 0rem;
    }
    
    .linkedInLogoSm {
        width: 6rem;
    }
}

// 1000px
@media screen and (max-width: 1001.324px){

    .code-text {
        font-size: 1.7rem;
    }

    .navbar {

        &__link1 {
            grid-column: 2 / 10;
            justify-self: start;
        }
        
    }
    .hamburger-menu {
        grid-column: 13 / 14;
    }
}

// 900px
@media screen and (max-width: 901.324px){

    .hamburger-menu {
        justify-self: start;
    }
    .closeX {
        justify-self: start;
    }
}

// 800px
@media screen and (max-width: 800px){

    .hamburger-menu {
        grid-column: 13 / 14;
        align-self: center;
        margin-right: -4rem;
    }
    .closeX {
        margin-right: -4rem;
    }
}

// 466px
@media screen and (max-width: 466px){
    .code-text {
        font-size: 1.5rem;
    }
    .hamburger-menu {
        align-self: center;
        justify-self: start;
        width: 4rem;
    }
    .closeX {
        width: 4rem;
        align-self: center;
        justify-self: start;
    }
}

// 384px
@media screen and (max-width: 384px) {
    .code-text {
        font-size: 1.4rem;
    }
}

// 377px
@media screen and (max-width: 377px){
    .hamburger-menu {
        align-self: center;
        justify-self: start;
        width: 4rem;
    }
    .navbar__link1 {
        grid-column: 2 / 12;
    }
    .closeX {
        width: 4rem;
        align-self: center;
        justify-self: start;
    }
}

// 338px 
@media screen and (max-width: 338px) {
    .navbar__link1 {
        grid-column: 2 / 12;
    }
    .code-text {
        font-size: 1.3rem;
    }
}

///////////////////////////  DEVICES  //////////////////////////////
//////////////////////////////////////////////////////////////////


// Galaxy Note 3 Android 4.3
// Galaxy Note S5 Android 5.0 Chrome 67

// Portrait
@media screen and (max-width: 360px){
    @media screen and (max-height: 640px){

        .navbar {

            &__link1 {
                grid-column: 2 / 12;
                justify-self: start;
            }
            
        }

        .hamburger-menu {
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
            width: 3.5rem;
        }
        .closeX {
            width: 3.5rem;
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
        }
    }
} 

///////////////////////////////////////

// Landscape
@media screen and (max-width: 640px){
    @media screen and (max-height: 360px){
        .code-text {
            font-size: 1.5rem;
        }

        .navbar {

            &__link1 {
                grid-column: 2 / 12;
                justify-self: start;
            }
            height: 5rem;
            
        }

        .hamburger-menu {
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
            width: 3.5rem;
        }
        .closeX {
            width: 3.5rem;
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
        }
    }
} 

// Landscape
@media screen and (max-width: 640px) and (orientation: landscape) {
        .code-text {
            font-size: 1.5rem;
        }

        .navbar {

            &__link1 {
                grid-column: 2 / 12;
                justify-self: start;
            }
            height: 5rem;
            
        }

        .hamburger-menu {
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
            width: 3.5rem;
        }
        .closeX {
            width: 3.5rem;
            align-self: center;
            justify-self: start;
            margin-right: 0rem;
        }
}