
.slideNav__box {

    display: grid;
    grid-template-rows: repeat(8, 6.5rem);
    border-radius: .2rem;
    background: linear-gradient(to top, rgba(214, 214, 214, .2), rgb(65, 64, 64), #000 80%);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0%;
    z-index: 600;

    &__link1 {
        grid-row: 2 / 3;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link2 {
        grid-row: 3 / 4;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link3 {
        grid-row: 4 / 5;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link4 {
        grid-row: 5 / 6;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link5 {
        grid-row: 6 / 7;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link6 {
        grid-row: 7 / 8;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }
    &__link7 {
        grid-row: 8 / 9;
        align-self: center;
        justify-self: center;
        cursor: pointer;
    }


}


////////////////////////////////////////////////////////////
//////////////////////// DEVICES //////////////////////////
//////////////////////////////////////////////////////////


// Portrait
// Nexus 10 
@media screen and (max-height: 1280px) and (orientation: portrait) {

    .slideNav__box {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(8, 8rem);
    }
}

// Portrait
// 800px
@media screen and (max-height: 800px) and (orientation: portrait) {

    .slideNav__box {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(8, 6rem);
    }
}

////////////////////////////////////////////
///////////////////////////////////////////

// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (orientation: landscape) {
    .slideNav__box {

        grid-template-rows: repeat(8, 5rem);
    
        &__link7 {
            margin-top: -2rem;
        }
    }
}

// Landacape
// WIDTH OF 800px
@media screen and (max-width: 926px) and (orientation: landscape) {

    .slideNav__box {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(8, 4.3rem);
    align-content: end;
    }
}

// Landacape
// Galaxy S9
@media screen and (max-height: 360px) and (max-width: 740px) {

    .slideNav__box {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(8, 4.3rem);
    align-content: end;
    }
}

// Landacape
// iPhone 6/7/8 /Galaxy Note 3
@media screen and (max-height: 375px) and (orientation: landscape) {

    .slideNav__box {
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(8, 4.3rem);
    }
}
