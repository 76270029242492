.section4-container {
    grid-row: 4 / 5;
    grid-column: full-start / full-end;
    background-color: #000;

    display: grid;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
    grid-template-rows: repeat(5, 1fr);



    // WHITE BACKGROUND
    .white-background {
        grid-row: 1 / 6;
        grid-column: full-start / full-end;
        background-color: #fff;
        width: 85%;
        height: 100%;
        align-self: end;
        justify-self: center;

        display: grid;

        &__border-line {
            grid-row: 1 / 7;
            width: .2rem;
            height: 100%;
            box-shadow: .1rem .1rem .1rem #ff5924;
            margin-left: 4rem;
        }
        &__paper-line1 {
            grid-row: 1 / 2;
            // grid-column: 1 / 2;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line2 {
            grid-row: 2 / 3;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line3 {
            grid-row: 3 / 4;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line4 {
            grid-row: 4 / 5;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line5 {
            grid-row: 5 / 6;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
        &__paper-line6 {
            grid-row: 6 / 7;
            width: 100%;
            height: .2rem;
            box-shadow: .1rem .1rem .1rem #739bc0;
            align-self: center;
        }
    }
}




///////////////////////////  DEVICES  //////////////////////////////
//////////////////////////////////////////////////////////////////


// Landscape
// For The Nexus 7 / And Others...
@media screen and (max-height: 600px) and (orientation: landscape) {
    .section4-container {
        grid-row: 6 / 8;
    }
}

// Landscape
// Galaxy Note 9
@media screen and (max-height: 428px) and (max-width: 926px) {
    .section4-container {
        grid-row: 6 / 8;
    }
}

// Landscape
// Galaxy Note 9
@media screen and (max-height: 414px) and (max-width: 896px) {
    .section4-container {
        grid-row: 6 / 8;
    }
}

// Landscape
@media screen and (max-height: 360px) and (max-width: 740px) {
    .section4-container {
        grid-row: 6 / 8;
    }
}

// Landscape
@media screen and (max-width: 640px){
    @media screen and (max-height: 360px){
        .section4-container {
            grid-row: 6 / 8;
        }
        
    }
} 