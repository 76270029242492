.case-study-container {
    display: grid;
    grid-template-rows: 100vh  100vh 100vh 100vh 15rem;
    grid-template-columns: [full-start] repeat(6, [body-start] minmax(10rem, 1fr) [body-end]) [full-end];
  
    position: relative;
    background-color: #000;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.theProblem-container {
    grid-row: 1 / 2;
    grid-column: full-start / full-end;
    background-color: var(--my-background);
    z-index: 100;

    display: grid;
    grid-template-columns: [full-start] repeat(6, 1fr) [full-end];
    grid-template-rows: repeat(5, 1fr);

}

.header {
    grid-column: 2 / 5 ;
    grid-row: 1;
    align-self: center;
    color: #fff;
}